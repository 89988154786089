/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/apps/company-management/company'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Company'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/communication/com006.svg'
        title='User'
        fontIcon='bi bi-circle-square fs-2x'
      />
      <SidebarMenuItemWithSub
        to=''
        title='Graph Indicator'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen032.svg'
      >
        <SidebarMenuItem
          to='/apps/indicator/create'
          title='Setup Company Indicator'
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
          to='/apps/indicator/graph'
          title='Generate Graph'
          fontIcon='bi-app-indicator'
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        title='Q&A'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen005.svg'
      >
        <SidebarMenuItem
          to='/apps/question/template'
          title='Setup Template Question'
          fontIcon='bi-app-indicator'
        />

        <SidebarMenuItem
          to='/apps/question/compile'
          title='Setup Company Q&A'
          fontIcon='bi-app-indicator'
        />

        <SidebarMenuItem
          to='/apps/question/details'
          title='View Company Q&A'
          fontIcon='bi-app-indicator'
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/resources/files'
        icon='/media/icons/duotune/files/fil012.svg'
        title='Resources'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/logout'
        icon='/media/icons/duotune/arrows/arr002.svg'
        title='Sign Out'
        fontIcon='bi-app-indicator'
      />
    </>
  )
}

export {SidebarMenuMain}
