import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {ResourceFilesPage} from './files'

const ResourcesPages = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='files' element={<ResourceFilesPage />} />
      </Route>
    </Routes>
  )
}

export default ResourcesPages
