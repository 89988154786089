import {TableExportButton} from './TableExportButton'
import {TableSearchInput} from './TableSearchInput'

const TableHeader = (props: {placeHolder: string}) => {
  return (
    <div className='card-header border-0 pt-6'>
      <TableSearchInput placeHolder={props.placeHolder} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <TableExportButton />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TableHeader}
