/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {StepperComponent} from '../../../../assets/ts/components'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Step1} from './step1'
import {Step2} from './step2'
import {Step3} from './step3'
import {
  CompanyIndicator,
  defaultIndicatorData,
  Indicator,
  Category,
  MainIndicator,
} from '../../../../modals/indicator'

const CreateIndicator = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [data, setData] = useState<CompanyIndicator>(defaultIndicatorData)
  const [hasError, setHasError] = useState(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInstance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    loadStepper()
  })

  const updateData = (fieldsToUpdate: Partial<CompanyIndicator>) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const updateCategory = (isNew = true, category?: Category, index?: any) => {
    const companyIndicator: CompanyIndicator = {...data, categories: data.categories}

    if (isNew) {
      companyIndicator.categories.push({
        title: '',
        tags: [],
        mainIndicators: [{title: '', unit: '', subIndicators: []}],
      })
    } else {
      companyIndicator.categories[index] = category!
    }
    setData(companyIndicator)
  }

  const removeCategory = (categoryIndex: any) => {
    const companyIndicator: CompanyIndicator = {...data}

    companyIndicator.categories.splice(categoryIndex, 1)
    setData(companyIndicator)
  }

  const updateMainIndicator = (
    isNew = true,
    categoryIndex: any,
    mainIndicator?: MainIndicator,
    index?: any
  ) => {
    const companyIndicator: CompanyIndicator = {...data}
    const mainIndicatorList = companyIndicator.categories[categoryIndex].mainIndicators

    if (isNew) {
      mainIndicatorList.push({
        title: '',
        unit: '',
        subIndicators: [],
      })
    } else {
      mainIndicatorList[index] = mainIndicator!
    }

    setData(companyIndicator)
  }

  const removeMainIndicator = (categoryIndex: any, mainIndicatorIndex: any) => {
    const companyIndicator: CompanyIndicator = {...data}

    companyIndicator.categories[categoryIndex].mainIndicators.splice(mainIndicatorIndex, 1)
    setData(companyIndicator)
  }

  const updateSubIndicator = (
    isNew = true,
    categoryIndex: any,
    mainIndicatorIndex: any,
    subIndicator: any,
    subIndicatorIndex: any
  ) => {
    const companyIndicator: CompanyIndicator = {...data}
    const subIndicatorList =
      companyIndicator.categories[categoryIndex].mainIndicators[mainIndicatorIndex].subIndicators

    if (isNew) {
      subIndicatorList.push({
        title: '',
        unit: '',
      })
    } else {
      subIndicatorList[subIndicatorIndex] = subIndicator!
    }

    setData(companyIndicator)
  }

  const removeSubIndicator = (
    categoryIndex: any,
    mainIndicatorIndex: any,
    subIndicatorIndex: any
  ) => {
    const companyIndicator: CompanyIndicator = {...data}

    companyIndicator.categories[categoryIndex].mainIndicators[
      mainIndicatorIndex
    ].subIndicators.splice(subIndicatorIndex, 1)

    setData(companyIndicator)
  }

  const checkAppBasic = (): boolean => {
    // if (!data.appBasic.appName || !data.appBasic.appType) {
    //   return false
    // }
    return true
  }

  const checkAppDataBase = (): boolean => {
    // if (!data.appDatabase.databaseName || !data.appDatabase.databaseSolution) {
    //   return false
    // }

    return true
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (!checkAppBasic()) {
        setHasError(true)
        return
      }
    }

    if (stepper.current.getCurrentStepIndex() === 3) {
      if (!checkAppDataBase()) {
        setHasError(true)
        return
      }
    }

    stepper.current.goNext()
  }

  const submit = () => {
    window.location.reload()
  }

  const stepperLabels = [
    {
      title: 'Company',
      description: 'Choose your Company',
    },
    {
      title: 'Indicator',
      description: 'Create new indicator',
    },
    {
      title: 'Review',
      description: 'Review and Submit',
    },
  ]

  return (
    <div>
      {' '}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Performance Indicator</span>
        </h3>
      </div>
      {/*begin::Stepper */}
      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_create_app_stepper'
      >
        {/* begin::Aside*/}
        <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
          <div className='stepper-nav ps-lg-10'>
            {stepperLabels.map((label, index) => {
              return (
                <div
                  className={index === 0 ? 'stepper-item current' : 'stepper-item'}
                  data-kt-stepper-element='nav'
                  key={index}
                >
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>{index + 1}</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>{label.title}</h3>

                      <div className='stepper-desc'>{label.description}</div>
                    </div>
                  </div>

                  <div className='stepper-line h-40px'></div>
                </div>
              )
            })}
          </div>
        </div>
        {/* begin::Aside*/}

        {/*begin::Content */}
        <div className='flex-row-fluid py-lg-5 px-lg-15'>
          {/*begin::Form */}
          <form noValidate id='kt_modal_create_app_form'>
            <Step1 data={data} updateData={updateData} hasError={hasError} />
            <Step2
              data={data}
              updateCategory={updateCategory}
              removeCategory={removeCategory}
              updateMainIndicator={updateMainIndicator}
              removeMainIndicator={removeMainIndicator}
              updateSubIndicator={updateSubIndicator}
              removeSubIndicator={removeSubIndicator}
              updateData={updateData}
              hasError={hasError}
            />
            <Step3 data={data} updateData={updateData} hasError={hasError} />

            {/*begin::Actions */}
            <div className='d-flex flex-stack pt-10'>
              <div className='me-2'>
                <button
                  type='button'
                  className='btn btn-lg btn-light-primary me-3'
                  data-kt-stepper-action='previous'
                  onClick={prevStep}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr063.svg'
                    className='svg-icon-3 me-1'
                  />{' '}
                  Previous
                </button>
              </div>
              <div>
                <button
                  type='button'
                  className='btn btn-lg btn-primary'
                  data-kt-stepper-action='submit'
                  onClick={submit}
                >
                  Submit{' '}
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
                </button>

                <button
                  type='button'
                  className='btn btn-lg btn-primary'
                  data-kt-stepper-action='next'
                  onClick={nextStep}
                >
                  Next Step{' '}
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-1 me-0'
                  />
                </button>
              </div>
            </div>
            {/*end::Actions */}
          </form>
          {/*end::Form */}
        </div>
        {/*end::Content */}
      </div>
      {/* end::Stepper */}
    </div>
  )
}

export {CreateIndicator}
