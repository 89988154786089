/* eslint-disable jsx-a11y/anchor-is-valid */
import Select from 'react-select'

const options = [
  {value: '1', label: 'Quarter 1'},
  {value: '2', label: 'Quarter 2'},
  {value: '3', label: 'Quarter 3'},
  {value: '4', label: 'Quarter 4'},
]

const QuarterSelect = () => <Select options={options} />

export {QuarterSelect}
