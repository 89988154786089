/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {RandomColor} from '../../../_metronic/helpers/UIHelpers'

type Props = {
  className: string
  color: string
  title: string
  description: string
  progress: string
  showUser: boolean
  users?: string[]
}

const ProgressCard: React.FC<Props> = ({
  className,
  color,
  title,
  description,
  progress,
  showUser,
  users,
}) => {
  return (
    <div className={`card bg-light-${color} ${className}`}>
      {/* begin::Body */}
      <div className='card-body my-3'>
        <div className='row'>
          <div className='col-md-5'>
            <a href='#' className={`card-title fw-bold text-${color} fs-5 mb-3 d-block`}>
              {description}
            </a>
            <span className='text-dark fs-1 fw-bold me-2'>{progress}</span>
            <span className='fw-semibold text-muted fs-7'>{title}</span>
          </div>
          <div className='col-md-7'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mt-2'>
              <div className='fx-1 text-gray-800 fw-bolder'>March 10, 2023</div>
              <div className='fw-bold text-gray-500'>Due Date</div>
            </div>
          </div>
        </div>

        <div className={`progress h-7px bg-${color} bg-opacity-50 mt-7`}>
          <div
            className={`progress-bar bg-${color}`}
            role='progressbar'
            style={{width: progress}}
          />
        </div>

        {showUser && (
          <div className='symbol-group symbol-hover flex-nowrap mt-10'>
            {users?.map((user, index) => {
              return (
                <div
                  key={`${user}_${index}`}
                  className='symbol symbol-35px symbol-circle'
                  data-bs-toggle='tooltip'
                  title={user}
                >
                  <span
                    className={`symbol-label fw-bold bg-${RandomColor()}  text-inverse-warning`}
                  >
                    {user.charAt(0).toUpperCase()}
                  </span>
                </div>
              )
            })}
            <a href='#' className='symbol symbol-35px symbol-circle'>
              <span className='symbol-label fs-8 fw-bold bg-dark text-gray-300'>+24</span>
            </a>
          </div>
        )}
      </div>
      {/* end:: Body */}
    </div>
  )
}

export {ProgressCard}
