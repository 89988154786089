import {FC, useMemo} from 'react'

const EsgTypeRadioButton: FC = () => {
  return (
     
  <>
  {/* begin::Input group */}
  <div className='fv-row mb-7'>
  {/* begin::Label */}
  <label className='required fw-bold fs-6 mb-2'>Type</label>
  {/* end::Label */}

  {/* begin::Input */}
  <div className='mb-10 d-flex'>
    <div className='form-check form-check-custom form-check-solid me-10'>
      <input
        className='form-check-input h-30px w-30px'
        type='radio'
        value=''
        id='flexRadio30'
        name='type'
      />
      <label className='form-check-label' htmlFor='flexRadio30'>
        Environment
      </label>
    </div>

    <div className='form-check form-check-custom form-check-solid me-10'>
      <input
        className='form-check-input h-30px w-30px'
        type='radio'
        value=''
        id='flexRadio40'
        name='type'
      />
      <label className='form-check-label' htmlFor='flexRadio40'>
        Social
      </label>
    </div>

    <div className='form-check form-check-custom form-check-solid me-10'>
      <input
        className='form-check-input h-30px w-30px'
        type='radio'
        value=''
        id='flexRadio50'
        name='type'
      />
      <label className='form-check-label' htmlFor='flexRadio50'>
        Governance
      </label>
    </div>
  </div>
  {/* end::Input */}
</div>
{/* end::Input group */}
</>
  )
}

export {EsgTypeRadioButton}
