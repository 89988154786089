import { KTSVG } from "../../../_metronic/helpers"

const UserModal = () => {
  return (
    <>
      <div className='modal fade' id='kt_modal_users' role='dialog' tabIndex={-1} aria-modal='true'>
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-scrollable'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div className='card card-xl-stretch mb-xl-8'>
                <div className='card-header border-0'>
                  <h3 className='card-title fw-bold text-dark'>Users</h3>
                  <div className='card-toolbar'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    ></button>
                  </div>
                </div>
                <div className='card-body pt-2'>
                  <div className='d-flex align-items-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                      <div
                        className='symbol symbol-35px symbol-circle'
                        data-bs-toggle='tooltip'
                        title='Anna Smith'
                      >
                        <span className='symbol-label fw-bold bg-warning text-inverse-warning'>
                          AS
                        </span>
                      </div>
                    </div>
                    <div className='flex-grow-1'>
                      <a
                        href='/apps/profile/overview'
                        className='text-dark fw-bold text-hover-primary fs-6'
                      >
                        Anna Smith
                      </a>
                      <span className='text-muted d-block fw-semibold'>PIC</span>
                    </div>
                    <div className='flex-grow-1'>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  </div>
                  <div className='d-flex align-items-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                      <div
                        className='symbol symbol-35px symbol-circle'
                        data-bs-toggle='tooltip'
                        title='Anna Smith'
                      >
                        <span className='symbol-label fw-bold bg-primary text-inverse-warning'>
                          S
                        </span>
                      </div>
                    </div>
                    <div className='flex-grow-1'>
                      <a
                        href='/apps/profile/overview'
                        className='text-dark fw-bold text-hover-primary fs-6'
                      >
                        Smith Ben
                      </a>
                      <span className='text-muted d-block fw-semibold'>PIC</span>
                    </div>
                    <div className='flex-grow-1'>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  </div>
                  <div className='d-flex align-items-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                      <div
                        className='symbol symbol-35px symbol-circle'
                        data-bs-toggle='tooltip'
                        title='Anna Smith'
                      >
                        <span className='symbol-label fw-bold bg-danger text-inverse-warning'>
                          P
                        </span>
                      </div>
                    </div>
                    <div className='flex-grow-1'>
                      <a
                        href='/apps/profile/overview'
                        className='text-dark fw-bold text-hover-primary fs-6'
                      >
                        Pamela
                      </a>
                      <span className='text-muted d-block fw-semibold'>Reviewer</span>
                    </div>
                    <div className='flex-grow-1'>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  </div>{' '}
                  <div className='d-flex align-items-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                      <div
                        className='symbol symbol-35px symbol-circle'
                        data-bs-toggle='tooltip'
                        title='Anna Smith'
                      >
                        <span className='symbol-label fw-bold bg-warning text-inverse-warning'>
                          TTV
                        </span>
                      </div>
                    </div>
                    <div className='flex-grow-1'>
                      <a
                        href='/apps/profile/overview'
                        className='text-dark fw-bold text-hover-primary fs-6'
                      >
                        Thoo Te{' '}
                      </a>
                      <span className='text-muted d-block fw-semibold'>Approver</span>
                    </div>
                    <div className='flex-grow-1'>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}

      {/* end::Modal Backdrop */}
    </>
  )
}

export {UserModal}
