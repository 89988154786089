import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../layout/core'
import {CreateIndicatorPage} from './create'
import {IndicatorDetailsPage} from './details'
import {AssignIndicatorPage} from './assign'
import { GraphGeneratePage } from './graphGenerate'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Question',
    path: '/apps/viewquestion',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const IndicatorPages = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='create' element={<CreateIndicatorPage />}></Route>
        <Route path='progress' element={<IndicatorDetailsPage />}></Route>
        <Route path='assign' element={<AssignIndicatorPage />}></Route>
        <Route path='graph' element={<GraphGeneratePage />}></Route>
      </Route>
    </Routes>
  )
}

export default IndicatorPages
