import {Fragment} from 'react'
import date from 'date-and-time'

const IndicatorDailyInput = (props: {dates: Date[]}) => {
  return (
    <div className='row'>
      {props.dates.reverse().map((d: Date) => {
        return (
          <Fragment>
            <div className='col-md-3'>
              <p className='text-gray-600 fw-bold'> {date.format(d, 'DD MMM YYYY')}</p>
              <input type='number' className='form-control'></input>
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

export {IndicatorDailyInput as IndicatorDailyInput}
