import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard'
import {getCSSVariableValue} from '../assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import TemplateQuestionPage from '../pages/questions/template'
import AnswerQuestionPage from '../pages/questions/answer'
import CompileQuestionPage from '../pages/questions/compile'
import ProfilePage from '../pages/profile'
import ViewQuestionPage from '../pages/questions/view'
import IndicatorPages from '../pages/indicator'
import CompanyPage from '../pages/company'
import QuestionPages from '../pages/questions'
import ResourcesPages from '../pages/resouces'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../pages/user'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />

        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/company-management/*'
          element={
            <SuspensedView>
              <CompanyPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/question/*'
          element={
            <SuspensedView>
              <QuestionPages />
            </SuspensedView>
          }
        />

        <Route
          path='apps/indicator/*'
          element={
            <SuspensedView>
              <IndicatorPages />
            </SuspensedView>
          }
        />
        <Route
          path='apps/resources/*'
          element={
            <SuspensedView>
              <ResourcesPages />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
