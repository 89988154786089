/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const TablePagination = () => {
  return (
    <></>
    // <div className='row'>
    //   <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
    //   <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
    //     <div id='kt_table_users_paginate'>
    //       {/* <ul className='pagination'>
    //             <li
    //               key={link.label}
    //               className={clsx('page-item', {
    //                 active: pagination.page === link.page,
    //                 disabled: isLoading,
    //                 previous: link.label === 'Previous',
    //                 next: link.label === 'Next',
    //               })}
    //             >
    //               <a
    //                 className={clsx('page-link', {
    //                   'page-text': link.label === 'Previous' || link.label === 'Next',
    //                   'me-5': link.label === 'Previous',
    //                 })}
    //                 onClick={() => updatePage(link.page)}
    //                 style={{cursor: 'pointer'}}
    //               >
    //                 {mappedLabel(link.label)}
    //               </a>
    //             </li>
    //       </ul> */}
    //     </div>
    //   </div>
    // </div>
  )
}

export {TablePagination}
