import {ListLoading} from '../../../../components/loading/ListLoading'
import {UserActionsCell} from './UserActionsCell'
import {TablePagination} from '../../../../components/table-list/TablePagination'
import {KTCardBody} from '../../../../../_metronic/helpers'

const CompanyTable = () => {
  const isLoading = false

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th className='min-w-125px'>Company</th>
              <th className='min-w-125px'>Contact</th>
              <th className='min-w-125px'>Due Date</th>
              <th className='min-w-125px'>Position</th>
              <th className='min-w-125px'>Action</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='#'>
                      <div className='symbol-label'>
                        <img
                          src='https://www.bernama.com/storage/photos/906ee1715b9eca23c3179f92237d8a0c5c9510867dc19'
                          alt='Emma Smith'
                          className='w-100'
                        />
                      </div>
                    </a>
                  </div>
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                      Bursa
                    </a>
                    <span>Erik Finn</span>
                  </div>
                </div>
              </td>
              <td>
                0123456789
                <br></br>
                bursa@test.com
              </td>
              <td>23 March, 2023</td>
              <td>CEO</td>
              <td>
                <UserActionsCell id={1} />{' '}
              </td>
            </tr>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='#'>
                      <div className='symbol-label'>
                        <img
                          src='https://www.mtree.com.my/wp-content/uploads/2017/01/logo-sunway.jpg'
                          alt='Sunway'
                          className='w-100'
                        />
                      </div>
                    </a>
                  </div>
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                      Sunway
                    </a>
                    <span>Xiadani Dave</span>
                  </div>
                </div>
              </td>

              <td>
                0123456789<br></br>sunway@mail.com
              </td>
              <td>23 March, 2023</td>
              <td>Head of Finance</td>
              <td>
                <UserActionsCell id={1} />{' '}
              </td>
            </tr>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='#'>
                      <div className='symbol-label'>
                        <img
                          src='https://tehtalk.com/wp-content/uploads/2021/01/Maybank-logo-2011.png'
                          alt='Sunway'
                          className='w-100'
                        />
                      </div>
                    </a>
                  </div>
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                      Maybank
                    </a>
                    <span>Koay Zet Thum</span>
                  </div>
                </div>
              </td>

              <td>
                0123456789<br></br>maybank@email.com
              </td>
              <td>23 March, 2023</td>
              <td>CFO</td>
              <td>
                <UserActionsCell id={1} />{' '}
              </td>
            </tr>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='#'>
                      <div className='symbol-label'>
                        <img
                          src='https://upload.wikimedia.org/wikipedia/commons/b/b7/MyNEWS_logo.jpg'
                          alt='MyNews'
                          className='w-100'
                        />
                      </div>
                    </a>
                  </div>
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                      MyNews
                    </a>
                    <span>Soo Pik Koong</span>
                  </div>
                </div>
              </td>

              <td>
                0123456789
                <br></br>
                mynews@test.com
              </td>
              <td>23 March, 2023</td>

              <td>President</td>
              <td>
                <UserActionsCell id={1} />{' '}
              </td>
            </tr>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='#'>
                      <div className='symbol-label'>
                        <img
                          src='https://cf.shopee.com.my/file/9d640db5dcb357c2cf71073766d61714'
                          alt='Tomei'
                          className='w-100'
                        />
                      </div>
                    </a>
                  </div>
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                      Tomei
                    </a>
                    <span>Thoo Thee Vin</span>
                  </div>
                </div>
              </td>
              <td>
                0123456789<br></br>tomei@test.com
              </td>
              <td>20 February , 2023</td>
              <td>VP Production</td>
              <td>
                <UserActionsCell id={1} />{' '}
              </td>
            </tr>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='#'>
                      <div className='symbol-label'>
                        <img
                          src='https://www.farmfresh.com.my/wp-content/uploads/2021/08/LOGO-FARM-FRESHblack-01.png'
                          alt='FarmFresh'
                          className='w-100'
                        />
                      </div>
                    </a>
                  </div>
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                      Farm Fresh
                    </a>
                    <span>Yeo Kaw Keat</span>
                  </div>
                </div>
              </td>
              <td>
                0123456789 <br></br>ff@test.com
              </td>
              <td>20 February , 2023</td>
              <td>Project Manager</td>
              <td>
                <UserActionsCell id={1} />{' '}
              </td>
            </tr>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='#'>
                      <div className='symbol-label'>
                        <img
                          src='https://www.logolynx.com/images/logolynx/a6/a6ccd40284b94998629b222d5c4d343b.jpeg'
                          alt='AEON'
                          className='w-100'
                        />
                      </div>
                    </a>
                  </div>
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                      AEON
                    </a>
                    <span>Thang Bung Cheah</span>
                  </div>
                </div>
              </td>
              <td>
                0123456789 <br></br>aeon@test.com
              </td>
              <td>20 February , 2023</td>
              <td>Head of Marketing</td>
              <td>
                <UserActionsCell id={1} />{' '}
              </td>
            </tr>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='#'>
                      <div className='symbol-label'>
                        <img
                          src='https://upload.wikimedia.org/wikipedia/commons/7/73/Tenaga_Nasional.svg'
                          alt='Tenaga'
                          className='w-100'
                        />
                      </div>
                    </a>
                  </div>
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                      Tenaga
                    </a>
                    <span>Ahwali bin Roseli</span>
                  </div>
                </div>
              </td>
              <td>
                tenaga@mail.com<br></br>bursa@test.com
              </td>
              <td>20 February , 2023</td>
              <td>General Manager</td>
              <td>
                <UserActionsCell id={1} />{' '}
              </td>
            </tr>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    <a href='#'>
                      <div className='symbol-label'>
                        <img
                          src='https://media.glassdoor.com/sqll/42251/igb-corporation-squarelogo-1536143389948.png'
                          alt='IGB'
                          className='w-100'
                        />
                      </div>
                    </a>
                  </div>
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                      IGB
                    </a>
                    <span>Ahwali bin Roseli</span>
                  </div>
                </div>
              </td>
              <td>
                0123456789<br></br>igb@test.com
              </td>
              <td>20 February , 2023</td>
              <td>General Manager</td>
              <td>
                <UserActionsCell id={1} />{' '}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <TablePagination />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export {CompanyTable}
