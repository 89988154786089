import {FC, useMemo} from 'react'

const TableCheckboxCell: FC = () => {
  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check-target='#kt_table_users .form-check-input'
      />
    </div>
  )
}

export {TableCheckboxCell}
