/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import Select from 'react-select'

const options = [
  {value: 'Bursa', label: 'Bursa'},
  {value: 'Maybank', label: 'Maybank'},
  {value: 'MyNews', label: 'MyNews'},
  {value: 'MyNews', label: 'Tomei'},
  {value: 'MyNews', label: 'MyNews'},
  {value: 'MyNews', label: 'MyNews'},
  {value: 'MyNews', label: 'MyNews'},
]

const CompanySelect = () => <Select options={options} />

export {CompanySelect}
