import {Children, Fragment, ReactNode} from 'react'

export interface IndicatorTableModal {
  indicators: IndicatorModal[]
  action: ReactNode
}
export interface IndicatorModal {
  id: number
  title: string
  mainIndicators: MainIndicatorModal[]
}
export interface MainIndicatorModal {
  id: number
  indicator: string
  unit: string
  subIndicators: SubIndicatorModal[]
}
export interface SubIndicatorModal {
  id: number
  unit: string
  indicator: string
}

const IndicatorTable = (props: React.PropsWithChildren<IndicatorTableModal>) => {
  const indicators = props.indicators

  return (
    <>
      <div className='accordion' id='kt_accordion_question'>
        <div className='table-responsive'>
          <table id='kt_table_users' className='w-100'>
            <thead className='bg-active-primary text-white active'>
              <tr className=''>
                <th className='w-70%'>PERFORMANCE INDICATOR</th>
                <th className='w-20%'>UNITS</th>
                <th className='w-10%'> </th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>
              {indicators.map((indicator) => {
                return (
                  <Fragment>
                    <tr className='bg-success text-white fw-bold' style={{textAlign: 'left'}}>
                      <th colSpan={4}>{indicator.title}</th>
                    </tr>
                    {indicator.mainIndicators.map((main) => {
                      return (
                        <>
                          <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                            <td>
                              <a
                                href='#'
                                data-bs-toggle='collapse'
                                data-bs-target={'#kt_accordion_1_body_' + main.id}
                              >
                                {main.indicator}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                data-bs-toggle='collapse'
                                data-bs-target={'#kt_accordion_1_body_' + main.id}
                              >
                                {main.unit}
                              </a>
                            </td>
                            <td>{props.action}</td>
                          </tr>
                          <tr className='bg-light-primary hoverable'>
                            <td colSpan={4}>
                              <div
                                id={'kt_accordion_1_body_' + main.id}
                                className='accordion-collapse collapse tab-content px-9 hover-scroll-overlay-y pe-7 me-3 mb-2'
                                aria-labelledby='kt_accordion_1_header_1'
                                data-bs-parent='#kt_accordion_1'
                                style={{maxHeight: 400}}
                              >
                                <div className='accordion-body'>
                                  <div className='card bg-light-warning card-xl-stretch mb-xl-8'>
                                    <div className='card-body my-3'>
                                      <div className='mb-7 ps-10'>{props.children}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>

                          {main.subIndicators.map((sub) => {
                            return (
                              <>
                                <tr
                                  className='bg-light-primary hoverable'
                                  style={{textAlign: 'left'}}
                                >
                                  <td className='px-5'>
                                    <a
                                      href='#'
                                      data-bs-toggle='collapse'
                                      data-bs-target={'#kt_accordion_1_sub_' + sub.id}
                                    >
                                      {sub.indicator}
                                    </a>
                                  </td>
                                  <td>
                                    <a
                                      href='#'
                                      data-bs-toggle='collapse'
                                      data-bs-target={'#kt_accordion_1_sub_' + sub.id}
                                    >
                                      {sub.unit}
                                    </a>
                                  </td>
                                  <td>{props.action}</td>
                                </tr>
                                <tr className='bg-light-primary hoverable'>
                                  <td colSpan={4}>
                                    <div
                                      id={'kt_accordion_1_sub_' + sub.id}
                                      className='accordion-collapse collapse tab-content px-9 hover-scroll-overlay-y pe-7 me-3 mb-2'
                                      aria-labelledby='kt_accordion_1_header_1'
                                      data-bs-parent='#kt_accordion_1'
                                      style={{maxHeight: 400}}
                                    >
                                      <div className='accordion-body'>
                                        <div className='card bg-light-warning card-xl-stretch mb-xl-8'>
                                          <div className='card-body my-3'>
                                            <div className='mb-7 ps-10'>{props.children}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                        </>
                      )
                    })}
                  </Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export {IndicatorTable}
