/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import Select from 'react-select'
import {sliderPropTypes} from 'rsuite/esm/Slider/Slider'

const options = [
  {value: '1', label: '2021'},
  {value: '2', label: '2022'},
  {value: '3', label: '2023'},
]

const YearSelect = (props: {placeholder: string; value: string}) => (
  <select className='form-control' placeholder={props.placeholder}>
    {options.map((opt) => {
      return (
        <option value={opt.value} selected={props.value === opt.value}>
          {opt.label}
        </option>
      )
    })}
  </select>
  // <Select options={options} placeholder={props.placeholder} />
)

export {YearSelect}
