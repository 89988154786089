import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {KTCard} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../layout/core'
import {TemplateListrapper} from './layout'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Question',
    path: '/apps/template/question',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AnswerQuestionPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}> Question </PageTitle>
      <KTCard>
        <div className='card'>
          <div className='card-header border-0 pt-1'>
            {/* begin::Card toolbar */}
            <div className='card-toolbar'>
              {/* begin::Group actions */}
              <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                    Environment
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                    Social
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_3'>
                    Governance
                  </a>
                </li>
              </ul>
              {/* end::Group actions */}
            </div>
            {/* end::Card toolbar */}

            <div className='card-body p-0'>
              <div className='tab-content' id='myTabContent'>
                <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
                  <TemplateListrapper />
                </div>
                <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
                  <TemplateListrapper />
                </div>
                <div className='tab-pane fade' id='kt_tab_pane_3' role='tabpanel'>
                  <TemplateListrapper />
                </div>
              </div>
            </div>
          </div>
        </div>
      </KTCard>
    </>
  )
}

export default AnswerQuestionPage
