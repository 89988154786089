import {KTCard} from '../../../../../_metronic/helpers'
import {ViewQuestionTable} from './table/ViewQuestionTable'
import { UserModal } from '../../../../components/modal/UserModal'
import { TableHeader } from '../../../../components/table-list/TableHeader'

const CompiledListWrapper = () => (
  <>
    <KTCard>
      <TableHeader placeHolder='Search Question'></TableHeader>
      <ViewQuestionTable />
    </KTCard>
    <UserModal />
  </>
)
export {CompiledListWrapper}
