import {TemplateTable} from './table/TemplateTable'
import {TemplateEditModal} from './modal/AddTemplateModal'
import {KTCard} from '../../../../../_metronic/helpers'
import { TemplateTableHeader } from './table/TemplateTableHeader'

const TemplateTableWrapper = () => {
  return (
    <>
      <KTCard>
        <TemplateTableHeader />
        <TemplateTable></TemplateTable>
      </KTCard>
      <TemplateEditModal />
    </>
  )
}


export {TemplateTableWrapper }
