/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'

const AnswerQuestionModal: FC = () => {
  return (
    <div
      className='modal fade'
      id='kt_modal_answer_question'
      role='dialog'
      tabIndex={-1}
      aria-modal='true'
    >
      {/* begin::Modal dialog */}
      <div className='modal-dialog modal-dialog-scrollable mw-650px'>
        {/* begin::Modal content */}
        <div className='modal-content'>
          {/* begin::Modal body */}
          <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
            <form id='kt_modal_add_company_form' className='form' noValidate>
              {/* begin::Scroll */}
              <div
                className='d-flex flex-column scroll-y me-n7 pe-7'
                id='kt_modal_add_user_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_add_user_header'
                data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                data-kt-scroll-offset='300px'
              >
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className='fw-bold fs-6 mb-2'>Question</label>
                  {/* end::Label */}
                  <label className='fw-bold fs-6 mb-2'>
                    Please list the firm’s three primary risks related to climate change and if any,
                    the firm´s climate-related opportunities
                  </label>
                </div>
                {/* end::Input group */}

                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className='required fw-bold fs-6 mb-2'>Answer</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <textarea
                    placeholder='Question'
                    rows={11}
                    name='Question'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    autoComplete='off'
                  />

                  {/* end::Input */}
                </div>
                {/* end::Input group */}

                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className='required fw-bold fs-6 mb-2'>Attachment</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <input
                    type='file'
                    multiple
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  />

                  {/* end::Input */}
                </div>
                {/* end::Input group */}
              </div>
              {/* end::Scroll */}

              {/* begin::Actions */}
              <div className='text-center pt-15'>
                <button
                  type='reset'
                  className='btn btn-light me-3'
                  data-bs-dismiss='modal'
                  data-kt-users-modal-action='cancel'
                >
                  Discard
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                >
                  <span className='indicator-label'>Submit</span>
                </button>
              </div>
              {/* end::Actions */}
            </form>
          </div>
          {/* end::Modal body */}
        </div>
        {/* end::Modal content */}
      </div>
      {/* end::Modal dialog */}
    </div>
  )
}

export {AnswerQuestionModal}
