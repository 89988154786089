const GenerateGraphButton = () => {
  return (
    <a
      className='btn btn-sm btn-icon btn-light-primary me-1'
      href='#'
      title='generate graph'
      data-bs-toggle='modal'
      data-bs-target='#modal_graph_generator'
    >
      <i className='bi bi-graph-down fs-3'></i>
    </a>
  )
}

export {GenerateGraphButton}
