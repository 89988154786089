import DateRangePicker from 'react-bootstrap-daterangepicker'
import {KTCard, KTCardBody} from '../../../../_metronic/helpers'
import {DateRangePickerBootstrap} from '../../../components/date/DateRangePicker'
import {PageLink, PageTitle} from '../../../layout/core'
import date from 'date-and-time'
import {Fragment, useState} from 'react'
import {GraphGeneratorModal} from '../../../components/modal/GraphGeneratorModal'
import {IndicatorTable} from '../../../components/table-list/indicatorTable'
import {IndicatorDailyInput} from '../../../components/form/IndicatorDailyInput'
import {months} from 'moment'
import React from 'react'
import {GenerateGraphButton} from './component/GenerateGraphButton'
import { IndicatorMonthlyInput } from '../../../components/form/IndicatorMonthlyInput'
import { IndicatorQuarterlyInputModal } from '../../../components/form/IndicatorQuarterlyInput'
import { IndicatorYearlyInputModal } from '../../../components/form/IndicatorYearlyInput'
import { IndicatorWeeklyInput } from '../../../components/form/IndicatorWeeklyInput'
const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Indicator',
    path: '/apps/indicator/progress',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const dateRanges = () => {
  var startDate = new Date('2022-01-01')
  var endDate = new Date()
  var loopDate = startDate
  var dateRanges: Date[] = []
  while (loopDate <= endDate) {
    dateRanges.push(loopDate)
    loopDate = date.addDays(loopDate, 1)
  }

  return dateRanges
}
const weekRanges = () => {
  var weekRanges = [
    {
      month: 'November',
      monthId: 11,
      weeks: [
        'Nov 7th - 13th (Week 1)',
        'Nov 14th - 20th (Week 2)',
        'Nov 21st - 27th (Week 3)',
        'Nov 28th - Dec 4th (Week 4)',
      ],
    },
    {
      month: 'December',
      monthId: 12,
      weeks: ['Dec 5th - 11th (Week 1)', 'Dec 12th - 18th (Week 2)'],
    },
  ]
  return weekRanges
}
const years = [2021, 2022]

const indicators = [
  {
    mainIndicators: [
      {
        id: 1,
        indicator: 'GHG emission intensity By Revenue',
        unit: 'tonnes CO2/RM million',
        subIndicators: [],
        data: [100, 200, 300],
      },
      {
        id: 2,
        indicator: 'GHG emission intensity By Generation',
        unit: 'kg CO2/MWh',
        subIndicators: [],
        data: [100, 200, 300],
      },
      {
        id: 3,
        indicator: 'GHG emission intensity By Production',
        unit: 'kg CO2/MWh',
        subIndicators: [],
        data: [100, 200, 300],
      },
    ],
    title: 'GHG Emissions',
    id: 1,
  },

  {
    mainIndicators: [
      {
        id: 4,
        indicator: 'Direct GHG emissions',
        unit: 'tonnes CO2',
        subIndicators: [
          {id: 1, indicator: 'Carbon Dioxide', unit: 'tonnes', data: [100, 200, 300]},
          {id: 2, indicator: ' Dioxide', unit: 'tonnes', data: [100, 200, 300]},
        ],
        data: [100, 200, 300],
      },
    ],
    title: 'Scope 1 : Direct GHG emissions',
    id: 2,
  },
]

const dates = dateRanges()

const GraphGeneratePage = () => {
  const [period, setPeriod] = useState<string>('daily')

  function onPeriodClick(period: string) {
    setPeriod(period)
  }

  const [graphData, setGraphData] = useState<any>()

  function setGraph(title: string, data: any) {
    var graph = {title: title, data: data}
    setGraphData(graph)
  }
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}> Indicator Graph Generate </PageTitle>

      {/* begin::Col */}
      <div className='col-md-12 p-0'>
        <KTCard>
          {period === 'yearly' && <div>year</div>}
          {period === 'monthly' && <div>monthly</div>}
          {period === 'quarterly' && <div>quarterly</div>}
          {period === 'weekly' && <div>weekly</div>}
          <div className='card'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Indicator Graph Generate </span>
              </h3>
              {/* begin::Card toolbar */}
              <div className='card-toolbar w-100'>
                {/* begin::Group actions */}
                <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                  <li className='nav-item'>
                    <a
                      className='nav-link active'
                      data-bs-toggle='tab'
                      href='#kt_tab_daily'
                      onClick={() => onPeriodClick('daily')}
                    >
                      Daily
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      data-bs-toggle='tab'
                      href='#kt_tab_weekly'
                      onClick={() => onPeriodClick('weekly')}
                    >
                      Weekly
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      data-bs-toggle='tab'
                      href='#kt_tab_monthly'
                      onClick={() => onPeriodClick('monthly')}
                    >
                      Monthly
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      data-bs-toggle='tab'
                      href='#kt_tab_quarterly'
                      onClick={() => onPeriodClick('quarterly')}
                    >
                      Quarterly
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      data-bs-toggle='tab'
                      href='#kt_tab_yearly'
                      onClick={() => onPeriodClick('yearly')}
                    >
                      Yearly
                    </a>
                  </li>
                </ul>
                {/* end::Group actions */}
              </div>
              {/* end::Card toolbar */}

              <div className='card-body card-scroll h-850px p-0'>
                <div className='tab-content' id='myTabContent'>
                  <div className='tab-pane fade active show' id='kt_tab_daily' role='tabpanel'>
                    <div className='mb-10'>
                      <label className='form-label fs-6 fw-bold'>Date:</label>
                      <DateRangePickerBootstrap></DateRangePickerBootstrap>
                    </div>
                    <IndicatorTable
                      indicators={indicators}
                      action={<GenerateGraphButton></GenerateGraphButton>}
                    >
                      <IndicatorDailyInput dates={dates}></IndicatorDailyInput>
                    </IndicatorTable>
                  </div>

                  <div className='tab-pane fade' id='kt_tab_weekly' role='tabpanel'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>From Year:</label>
                          <select className='form-control'>
                            <option selected> 2022 - January </option>
                            <option> 2022 - Febraury </option>
                            <option> 2022 - March </option>
                            <option> 2022 - April </option>
                            <option> 2022 - May </option>
                            <option> 2022 - June </option>
                            <option> 2022 - July </option>
                            <option> 2022 - August </option>
                            <option> 2022 - September </option>
                            <option> 2022 - October </option>
                            <option> 2022 - November </option>
                            <option> 2022 - December </option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>To Year:</label>
                          <select className='form-control'>
                            <option> 2022 - January </option>
                            <option> 2022 - Febraury </option>
                            <option> 2022 - March </option>
                            <option> 2022 - April </option>
                            <option> 2022 - May </option>
                            <option> 2022 - June </option>
                            <option> 2022 - July </option>
                            <option> 2022 - August </option>
                            <option> 2022 - September </option>
                            <option> 2022 - October </option>
                            <option> 2022 - November </option>
                            <option selected> 2022 - December </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <IndicatorTable indicators={indicators}  action={<GenerateGraphButton></GenerateGraphButton>}>
                      <IndicatorWeeklyInput weekRanges={weekRanges()}></IndicatorWeeklyInput>
                    </IndicatorTable>
                  </div>

                  <div className='tab-pane fade' id='kt_tab_monthly' role='tabpanel'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>From Year:</label>
                          <select className='form-control'>
                            <option> 2021 </option>
                            <option selected> 2022 </option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>To Year:</label>
                          <select className='form-control'>
                            <option> 2021 </option>
                            <option selected> 2022 </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <IndicatorTable indicators={indicators}  action={<GenerateGraphButton></GenerateGraphButton>}>
                      <IndicatorMonthlyInput years={years}></IndicatorMonthlyInput>
                    </IndicatorTable>
                    <div></div>
                  </div>
                  <div className='tab-pane fade' id='kt_tab_quarterly' role='tabpanel'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>From Year:</label>
                          <select className='form-control'>
                            <option> 2021 </option>
                            <option selected> 2022 </option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>To Year:</label>
                          <select className='form-control'>
                            <option> 2021 </option>
                            <option selected> 2022 </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <IndicatorTable indicators={indicators}  action={<GenerateGraphButton></GenerateGraphButton>}>
                      <IndicatorQuarterlyInputModal years={years}></IndicatorQuarterlyInputModal>
                    </IndicatorTable>
                  </div>
                  <div className='tab-pane fade' id='kt_tab_yearly' role='tabpanel'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>From Year:</label>
                          <select className='form-control'>
                            <option> 2021 </option>
                            <option selected> 2022 </option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>To Year:</label>
                          <select className='form-control'>
                            <option> 2021 </option>
                            <option selected> 2022 </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <IndicatorTable indicators={indicators}  action={<GenerateGraphButton></GenerateGraphButton>}>
                      <IndicatorYearlyInputModal years={years}></IndicatorYearlyInputModal>
                    </IndicatorTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </KTCard>
      </div>
      {/* end::Col */}

      <GraphGeneratorModal className='' period={period}></GraphGeneratorModal>
    </>
  )
}

export {GraphGeneratePage}
