import React from 'react'

type Props = {
  label: {
    text: string
    tooltip?: {
      text?: string
      show?: boolean
    }
  }
  input: {
    type: 'text' | 'password' | 'email' | 'number'
    name?: string
    placeholder?: string
    value?: any
    onChange?: any
    isRequired?: boolean
  }
}
const TextBox: React.FC<Props> = ({label, input}: Props) => {
  return (
    <div className='row'>
      <div className='col-md-4'>
        <label className='d-flex align-items-center fs-5 mt-2'>
          <span className={input.isRequired ? 'required' : ''}>{label.text}</span>
          {label.tooltip?.show ? (
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title={label.tooltip?.text}
            ></i>
          ) : (
            ''
          )}
        </label>
      </div>
      <div className='col-md-8'>
        <input
          type={input.type || 'text'}
          className='form-control form-control-lg form-control-solid'
          name={input.name}
          placeholder={input.placeholder}
          value={input.value}
          onChange={(e) => input.onChange(e.target.value)}
        />
      </div>
    </div>
  )
}

export {TextBox}
