import {CompiledListHeader, TemplateListHeader} from './TableHeader'
import {TemplateTable} from './TemplateTable'
import {KTCard} from '../../../../../../_metronic/helpers'
import {CompileQuestionTable} from './CompiledQuestionTable'

const TemplateList = () => {
  return (
    <>
      <KTCard>
        <TemplateListHeader />
        <TemplateTable />
      </KTCard>
    </>
  )
}

const TemplateListrapper = () => <TemplateList />
const CompiledListWrapper = () => (
  <>
    <KTCard>
      <CompiledListHeader />
      <CompileQuestionTable />
    </KTCard>
  </>
)
export {TemplateListrapper, CompiledListWrapper}
