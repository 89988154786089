/* eslint-disable jsx-a11y/anchor-is-valid */
import Select from 'react-select'

const options = [
  {value: 'Pie', label: 'Pie', icon: 'fa-solid fa-chart-area text-success fs-3x p-3 '},
  {value: 'Line', label: 'Line', icon: 'fa-solid fa-chart-area text-success fs-3x p-3 '},
  {value: 'Bar', label: 'Bar', icon: 'fa-solid fa-chart-column text-success fs-3x p-3 '},
  {value: 'Area', label: 'Area', icon: 'fa-solid fa-chart-area text-success fs-3x p-3 '},
  {value: 'Donut', label: 'Donut', icon: 'fa-solid fa-chart-area text-success fs-3x p-3'},
]

const GraphTypeSelect = ({onChange}: any) => (
  <Select
    options={options}
    onChange={(e) => {
      onChange(e?.value)
    }}
  />

  // <div className='row'>
  //   {/*begin::Col*/}
  //   {options.map((opt) => {
  //     return (
  //       <>
  //         <div className='col-4'>
  //           <label className='form-check-clip text-center'>
  //             <input
  //               className='btn-check'
  //               type='radio'
  //               defaultValue={opt.value}
  //               defaultChecked
  //               name='option'
  //               onChange={(e:any) => {
  //                 onChange(e.value)
  //               }}
  //             />
  //             <div className='form-check-wrapper'>
  //               <div className='form-check-indicator' />
  //               <i className={opt.icon + 'form-check-content '}></i>
  //             </div>
  //             <div className='form-check-label'>{opt.label}</div>
  //           </label>
  //         </div>
  //       </>
  //     )
  //   })}

  //   {/*end::Col*/}
  // </div>
  /*end::Row*/
)

export {GraphTypeSelect}
