/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Card4} from '../../../../_metronic/partials/content/cards/Card4'

const level1 = {
  files: [
    {name: 'CSR', type: 'folder'},
    {name: 'Gallery', type: 'folder'},
    {name: 'Documents', type: 'folder'},
    {name: 'Project Requ...', type: 'pdf', counter: 1},
    {name: 'CRM App Docs', type: 'doc', counter: 2},
    {name: 'ESG Logo', type: 'ai', counter: 3},
  ],
  path: [{name: 'Resources', value: ''}],
}
export function ResourceFilesPage() {
  const [files, setFiles] = useState<any>(level1)
  const viewFiles = (name: string) => {
    if (name === '') {
      setFiles(level1)
    }
    if (name === 'CSR') {
      var file = {
        files: [
          {name: '2021', type: 'folder', counter: 1},

          {name: '2022', type: 'folder', counter: 2},

          {name: '2023', type: 'folder', counter: 3},
        ],

        path: [
          {name: 'Resources', value: ''},
          {name: 'CSR', value: 'CSR'},
        ],
      }

      setFiles(file)
    }

    if (name === '2021' || name === '2022' || name === '2023') {
      var file = {
        files: [
          {name: 'Project Requ...', type: 'pdf', counter: 1},
          {name: 'CRM App Docs', type: 'doc', counter: 2},
          {name: 'ESG Logo', type: 'ai', counter: 3},
          {name: 'Document 4', type: 'doc', counter: 4},
          {name: 'Document 5', type: 'doc', counter: 5},
          {name: 'Document 6', type: 'doc', counter: 6},
          {name: 'Image 1', type: 'jpg', counter: 7},
          {name: 'Image 2', type: 'jpg', counter: 8},
        ],
        path: [
          {name: 'Resources', value: ''},
          {name: 'CSR', value: 'CSR'},
          {name: name, value: name},
        ],
      }
      setFiles(file)
    }
    if (name === 'Gallery') {
      var file = {
        files: [
          {name: 'Project Requ...', type: 'pdf', counter: 1},
          {name: 'CRM App Docs', type: 'doc', counter: 2},
          {name: 'ESG Logo', type: 'ai', counter: 3},
          {name: 'Document 4', type: 'doc', counter: 4},
          {name: 'Document 5', type: 'doc', counter: 5},
          {name: 'Document 6', type: 'doc', counter: 6},
          {name: 'Image 1', type: 'tif', counter: 7},
          {name: 'Image 2', type: 'tif', counter: 8},
        ],
        path: [
          {name: 'Resources', value: ''},
          {name: 'Gallery', value: 'Gallery'},
        ],
      }
      setFiles(file)
    }
    if (name === 'Documents') {
      var file = {
        files: [
          {name: 'Project Requ...', type: 'pdf', counter: 1},
          {name: 'CRM App Docs', type: 'doc', counter: 2},
          {name: 'GRI Standard', type: 'pdf', counter: 3},
          {name: 'GRI Template', type: 'doc', counter: 4},
          {name: 'Environment Sam...', type: 'pdf', counter: 5},
          {name: 'Social Sample', type: 'pdf', counter: 6},
          {name: 'Reference 1', type: 'pdf', counter: 7},
          {name: 'Reference 2', type: 'pdf', counter: 8},
        ],
        path: [
          {name: 'Resources', value: ''},
          {name: 'Documents', value: 'Documents'},
        ],
      }
      setFiles(file)
    }
  }
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          My Documents
          <span className='fs-6 text-gray-400 fw-bold ms-1'>100+ resources</span>
        </h3>

        <div className='d-flex my-2'>
          <div className='d-flex align-items-center position-relative me-4'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-3 position-absolute ms-3'
            />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-white form-control-sm w-150px ps-9'
              placeholder='Search'
            />
          </div>

          <a href='#' className='btn btn-primary btn-sm'>
            New Folder
          </a>
          <a href='#' className='btn btn-primary btn-sm mx-2'>
            New File
          </a>
        </div>
      </div>

      <div className='row mb-8'>
        <div className='col-md-12'>
          {files.path.map((p: any, index: number) => {
            return (
              <>
                <a href='#' className='text-primary fw-bold' onClick={() => viewFiles(p.value)}>
                  {p.name}
                </a>

                {index + 1 < files.path.length && (
                  <i className='fa-solid fa-arrow-right text-primary mx-2 my-2 mt-1'></i>
                )}
              </>
            )
          })}

          {/* <a href='#' className='text-primary fw-bold' onClick={() => viewFiles('CSR')}>
            CSR
          </a>
          <i className='fa-solid fa-arrow-right text-primary mx-2 my-2 mt-1'></i>
          <a href='#' className='text-primary fw-bold' onClick={() => viewFiles('2021')}>
            2021
          </a> */}
        </div>
      </div>

      <div className='row'>
        {files.files.map((f: any) => {
          return (
            <>
              {
                <div className='col-3 col-sm-3 col-md-3 mb-8'>
                  <a onClick={() => viewFiles(f.name)}>
                    <Card4
                      icon={
                        f.type === 'folder'
                          ? '/media/svg/files/folder-document.svg'
                          : '/media/svg/files/' + f.type + '.svg'
                      }
                      title={f.name}
                      description={f.type === 'folder' ? '2 files' : ''}
                    />
                  </a>
                </div>
              }
            </>
          )
        })}
      </div>
    </>
  )
}
