import {KTSVG} from '../../../../../_metronic/helpers'
import {TableSearchInput} from '../../../../components/table-list/TableSearchInput'

const CompanyTableHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <TableSearchInput placeHolder='Search Company' />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          {/* begin::Export */}
          <button type='button' className='btn btn-light-primary me-3'>
            <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
            Export
          </button>
          {/* end::Export */}

          {/* begin::Add user */}
          <button
            type='button'
            className='btn btn-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_add_user'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add Company
          </button>
          {/* end::Add user */}
        </div>
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {CompanyTableHeader }
