import {KTCard} from '../../../../_metronic/helpers'
import {CreateIndicator} from '../create/components/stepper'

const Indicator = () => {
  return (
    <>
      <KTCard>
        <CreateIndicator />
      </KTCard>
    </>
  )
}

const CreateIndicatorPage = () => <Indicator />

export { CreateIndicatorPage}
