import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import AnswerQuestionPage from './answer'
import CompileQuestionPage from './compile'
import TemplateQuestionPage from './template'
import ViewQuestionPage from './view'

const QuestionPages = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='details' element={<ViewQuestionPage />} />
        <Route path='answer' element={<AnswerQuestionPage />} />
        <Route path='compile' element={<CompileQuestionPage />} />
        <Route path='template' element={<TemplateQuestionPage />} />
      </Route>
    </Routes>
  )
}

export default QuestionPages
