/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuPIC = () => {
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/apps/question/answer'
        icon='/media/icons/duotune/coding/cod009.svg'
        title='Questions'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/apps/indicator/progress'
        icon='/media/icons/duotune/general/gen032.svg'
        title='Indicator'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/logout'
        icon='/media/icons/duotune/arrows/arr002.svg'
        title='Sign Out'
        fontIcon='bi-app-indicator'
      />
    </>
  )
}

export {SidebarMenuPIC}
