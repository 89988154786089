/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../app/assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const MixedWidget17: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart1 = new ApexCharts(chartRef.current, chart1Options(chartColor, chartHeight))
    if (chart1) {
      chart1.render()
    }

    return chart1
  }

  useEffect(() => {
    const chart1 = refreshChart()

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chart1Options = (chartColor: string, chartHeight: string): ApexOptions => {
  const baseColor = getCSSVariableValue('--kt-' + chartColor) as string
  const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light')
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const strokeColor = getCSSVariableValue('--kt-gray-300')

  const color1 = getCSSVariableValue('--kt-warning')
  const color1Light = getCSSVariableValue('--kt-warning-light')

  const color2 = getCSSVariableValue('--kt-success')
  const color2Light = getCSSVariableValue('--kt-success-light')

  const color3 = getCSSVariableValue('--kt-primary')
  const color3Light = getCSSVariableValue('--kt-primary-light')

  const color4 = getCSSVariableValue('--kt-info')
  const color4Light = getCSSVariableValue('--kt-info-light')

  return {
    series: [44, 55, 13, 33],
    labels: ['Answered', 'In Progress', 'Reviewed', 'Approved'],
    chart: {
      fontFamily: 'inherit',
      type: 'pie',
      height: 250,
    },
    plotOptions: {
      pie: {},
    },
    legend: {
      position: 'left',
      horizontalAlign: 'left',
      floating: true,
      offsetX: -5,
    },
    dataLabels: {
      enabled: true,

      formatter: function (val) {
        return Number(val).toFixed(2) + '%'
      },
      style: {
        fontSize: '18px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
      },
    },

    stroke: {
      curve: 'smooth',
      show: true,
      width: 2,
      colors: [color1, color2, color3, color4],
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '' + val + ''
        },
      },
    },
    colors: [color1, color2, color3, color4],
    markers: {
      colors: [color1, color2, color3, color4],
      strokeColors: [color1, color2, color3, color4],
      strokeWidth: 3,
    },
    title: {
      text: 'Overall Activity',
      align: 'left',
    },
  }
}

export {MixedWidget17}
