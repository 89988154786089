/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import Select from 'react-select'

const options = [
  {value: 'Standard Template 1', label: 'Standard Template 1'},
  {value: 'Standard Template 2', label: 'Standard Template 2'},

  {value: 'Finance', label: 'Finance'},
  {value: 'Technology', label: 'Technology'},
  {value: 'Oil & Gas', label: 'Oil & Gas'},

  {value: 'Template 1', label: 'Template 1'},
  {value: 'Template 2', label: 'Template 2'},
  {value: 'Bursa Template', label: 'Bursa Template'},
]

const IndicatorTemplateSelect = () => <Select options={options} />

export {IndicatorTemplateSelect}
