/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Select from 'react-select'

const options = [
  {value: 'Pie', label: 'Pie'},
  {value: 'Line', label: 'Line'},
  {value: 'Bar', label: 'Bar'},
  {value: 'Area', label: 'Area'},
  {value: 'Donut', label: 'Donut'},
]

function gettype(period: string, onChange: any) {
  if (period === 'yearly') {
    return (
      <>
        <div className='row'>
          <YearSelect select={false} onChange={() => onChange('yearly')}></YearSelect>
        </div>
      </>
    )
  }
  if (period === 'quarterly') {
    return (
      <>
        <div className='row'>
          <QuarterSelect select={true} onChange={() => onChange('quarterly')}></QuarterSelect>
          <YearSelect select={false} onChange={() => onChange('yearly')}></YearSelect>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className='row'>
          <MonthSelect select={false} onChange={() => onChange('monthly')}></MonthSelect>
          <QuarterSelect select={true} onChange={() => onChange('quarterly')}></QuarterSelect>
          <YearSelect select={false} onChange={() => onChange('yearly')}></YearSelect>
        </div>
      </>
    )
  }
}

const GraphPeriodSelect = (props: {period: string; onChange: any}) => {
  return <>{gettype(props.period, props.onChange)}</>
}

const QuarterSelect = (props: {select: boolean; onChange: any}) => {
  return (
    <>
      <div className='col-4'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='radio'
            value='1'
            id='quarterly'
            name='period'
            onChange={props.onChange}
          />
          <label className='form-check-label' htmlFor='quarterly'>
            Quarterly
          </label>
        </div>
      </div>
    </>
  )
}
const MonthSelect = (props: {select: boolean; onChange: any}) => {
  return (
    <>
      <div className='col-4'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='radio'
            value='3'
            id='monthly'
            name='period'
            onChange={props.onChange}
          />
          <label className='form-check-label' htmlFor='monthly'>
            Monthly
          </label>
        </div>
      </div>
    </>
  )
}
const YearSelect = (props: {select: boolean; onChange: any}) => {
  return (
    <>
      <div className='col-4'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='radio'
            value='2'
            id='yearly'
            name='period'
            onChange={props.onChange}
          />
          <label className='form-check-label' htmlFor='yearly'>
            Yearly
          </label>
        </div>
      </div>
    </>
  )
}

export {GraphPeriodSelect}
