import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'
import {
   
  ListsWidget5,
  ChartsWidget8,
} from '../../../../_metronic/partials/widgets'

export function Overview() {
  return (
    <>
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>

     
      </div>

      <div className='card-body p-9'>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>Max Smith</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Company</label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>Keenthemes</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            Contact Phone
            <i
              className='fas fa-exclamation-circle ms-1 fs-7'
              data-bs-toggle='tooltip'
              title='Phone number must be active'
            ></i>
          </label>

          <div className='col-lg-8 d-flex align-items-center'>
            <span className='fw-bolder fs-6 me-2'>044 3276 454 935</span>

          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Email</label>

          <div className='col-lg-8'>
            <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
              keenthemes.com
            </a>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            Role
            <i
              className='fas fa-exclamation-circle ms-1 fs-7'
              data-bs-toggle='tooltip'
              title='PIC'
            ></i>
          </label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>PIC</span>
          </div>
        </div>

         
        
      </div>
    </div>

    <div className='row gy-10 gx-xl-10'>
      <div className='col-xl-6'>
      <ChartsWidget8 className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>
      <div className='col-xl-6'>
        <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>

     
    </div>

    
  </>
  )
}
