/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageTitle} from '../../layout/core'
import {
  MixedWidget16,
  MixedWidget17,
  ListsWidget5,
} from '../../../_metronic/partials/widgets'
import {useAuth} from '../../services/auth'
import {CompanyAdminDashboard} from './CompanyAdmin'
import {PICDashboard} from './PersonInCharge'
import {SuperAdminDashboard} from './SuperAdmin'
import { TablesWidget9 } from '../../../_metronic/partials/widgets/tables/TablesWidget9'

const DashboardPage: FC = () => (
  <>
    <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-6'>
        <MixedWidget16 className='card-xxl-stretch' chartColor='success' chartHeight='300' />
      </div>
      <div className='col-xxl-6'>
        <MixedWidget17
          className='card-xxl-stretch mb-5 mb-xxl-8'
          chartColor='success'
          chartHeight='300'
        />
      </div>
    </div>

    <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-6'>
        <TablesWidget9 className='mb-5 mb-xl-8' />
      </div>
      <div className='col-xxl-6'>
        <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>
    </div>
  </>
)
const GetDashboard = () => {
  const {currentUser} = useAuth()
  if (currentUser?.role === 'superadmin') {
    return <SuperAdminDashboard />
  } else if (currentUser?.role === 'pic') {
    return <PICDashboard />
  } else if (currentUser?.role === 'company') {
    return <CompanyAdminDashboard />
  } else {
    return <DashboardPage />
  }
}

const DashboardWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <GetDashboard />
    </>
  )
}

export {DashboardWrapper}
