import {ApexOptions} from 'apexcharts'
import {FC} from 'react'
import { TablesWidget9 } from '../../../../../_metronic/partials/widgets/tables/TablesWidget9'
import {getCSSVariableValue} from '../../../../assets/ts/_utils'
import {ProgressCard} from '../../../../components/card/Progress'
import {AreaChart} from '../../../../components/graph/Area'
import {LogList} from '../../../../components/list/LogList'
import { AnswerQuestionModal } from '../../../../components/modal/AnswerQuestionModal'

const CompanyAdminStatistics: FC = () => {
  const color1 = getCSSVariableValue('--kt-warning')
  const color1Light = getCSSVariableValue('--kt-warning-light')

  const color2 = getCSSVariableValue('--kt-success')
  const color2Light = getCSSVariableValue('--kt-success-light')

  const color3 = getCSSVariableValue('--kt-primary')
  const color3Light = getCSSVariableValue('--kt-primary-light')

  const taskOverviewAreaOptions: ApexOptions = {
    title: {
      text: 'Task Overview',
      align: 'center',
    },
    series: [
      {
        name: 'Answered',
        data: [30, 30, 50, 50, 35, 35],
      },
      {
        name: 'Reviewed',
        data: [55, 20, 20, 20, 70, 70],
      },
      {
        name: 'Approved',
        data: [60, 60, 40, 40, 30, 30],
      },
    ],
    xaxis: {
      categories: ['jan', 'feb', 'mar', 'apr', 'may', 'jun'],
    },
    colors: [color1Light, color2Light, color3Light],
    markers: {
      colors: [color1, color2, color3],
      strokeColors: [color1, color2, color3],
      strokeWidth: 3,
    },
    stroke: {
      colors: [color1, color2, color3],
    },
  }

  return (
    <>
      <ProgressCard
        className='card-xl-stretch mb-xl-8'
        color='success'
        title='(65/100)'
        description='Overall Progress'
        progress='65%'
        showUser={false}
      />

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-7'>
          <TablesWidget9 className='mb-5 mb-xl-8' />
        </div>
        <div className='col-xl-5'>
          <AreaChart
            className='card-xxl-stretch mb-5 mb-xl-10'
            chartHeight='350px'
            options={taskOverviewAreaOptions}
          />
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <div className='card card-xxl-stretch mb-5 mb-xl-10'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Pending Question</span>
              </h3>
            </div>
            <div className='card-body card-scroll h-350px ml-2'>
              <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              >
                <tbody className='text-gray-600 fw-bold'>
                  <tr>
                    <td>1.</td>
                    <td>
                      Please list the firm’s three primary risks related to climate change and if
                      any, the firm´s climate-related opportunities
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>
                      How are purchases and waste managed? If the firm rely on any scarce resources,
                      please describe what efforts are made to mitigate the risk of those resources
                      becoming scarcer in the future, e.g. recycling, substitutes, or improved
                      resource efficiency?
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td>
                      Please list the firm’s (1-2) primary means of making a positive environmental
                      impact or minimising negative environmental impact
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>4.</td>
                    <td>
                      Provide an overview of your business and the market environment in which you
                      operate to contextualise your reporting for the reader.
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>5.</td>
                    <td>
                      Does the firm have a history of accidents? If so, how have these been managed?
                      Are there any preventive measures, such as policies?
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>6.</td>
                    <td>
                      Are your employees inspired by your efforts to become a more climate
                      conscious, socially responsible, ethically led organization?
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>7.</td>
                    <td>
                      Please state your targets for gender and cultural equality and indicate the
                      relative split of men/women at every level of the firm, particularly the Board
                      of Directors and management team
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>8.</td>
                    <td>
                      Please list the firm’s three primary risks related to climate change and if
                      any, the firm´s climate-related opportunities
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>9.</td>
                    <td>
                      Does the firm have a history of accidents? If so, how have these been managed?
                      Are there any preventive measures, such as policies?
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>10.</td>
                    <td>
                      How are purchases and waste managed? If the firm rely on any scarce resources,
                      please describe what efforts are made to mitigate the risk of those resources
                      becoming scarcer in the future, e.g. recycling, substitutes, or improved
                      resource efficiency?
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='col-xl-6'>
          <LogList
            className='card-xxl-stretch mb-5 mb-xl-10'
            logOptions={{
              title: 'Activities',
              description: '78 Questions',
              data: [
                {
                  title: 'Answered Question',
                  options: {
                    bold: false,
                    color: 'warning',
                  },
                },
                {
                  title:
                    'Question XXXX <span class="badge badge-light-success">Approved</span> by<a href="/apps/profile/overview" class="text-primary">#Xandier9910</a>',
                  // { ReactHtmlParser('')},
                  // {parseHtml('<h1>Hello html-react-parser</h1>')},

                  options: {
                    bold: true,
                    color: 'success',
                  },
                },
                {
                  title:
                    'Reviewer Ben99910 <span class="badge badge-light-warning">commented</span>  on question 1',
                  options: {
                    bold: true,
                    color: 'error',
                  },
                },
                {
                  title:
                    'Reviewer Ben99910 <span class="badge badge-light-warning">commented</span>  on question 1',
                  options: {
                    bold: true,
                    color: 'error',
                  },
                },
                {
                  title:
                    'Question XXXX <span class="badge badge-light-success">Approved</span> by<a href="/apps/profile/overview" class="text-primary">#kin810</a>',
                  options: {
                    bold: true,
                    color: 'success',
                  },
                },
                {
                  title:
                    'Question XXXX <span class="badge badge-light-success">Approved</span> by<a href="/apps/profile/overview" class="text-primary">#Xandier9910</a>',
                  // { ReactHtmlParser('')},
                  // {parseHtml('<h1>Hello html-react-parser</h1>')},
                  options: {
                    bold: true,
                    color: 'success',
                  },
                },
                {
                  title:
                    'Reviewer Ben99910 <span class="badge badge-light-warning">commented</span>  on question 1',
                  options: {
                    bold: true,
                    color: 'error',
                  },
                },
                {
                  title: 'Answered Question',
                  options: {
                    bold: false,
                    color: 'warning',
                  },
                },
                {
                  title:
                    'Reviewer Ben99910 <span class="badge badge-light-warning">commented</span>  on question 1',
                  options: {
                    bold: true,
                    color: 'error',
                  },
                },
                {
                  title:
                    'Question XXXX <span class="badge badge-light-success">Approved</span> by<a href="/apps/profile/overview" class="text-primary">#Xandier9910</a>',
                  // { ReactHtmlParser('')},
                  // {parseHtml('<h1>Hello html-react-parser</h1>')},
                  options: {
                    bold: true,
                    color: 'success',
                  },
                },
                {
                  title:
                    'Question XXXX <span class="badge badge-light-success">Approved</span> by<a href="/apps/profile/overview" class="text-primary">#Xandier9910</a>',
                  // { ReactHtmlParser('')},
                  // {parseHtml('<h1>Hello html-react-parser</h1>')},
                  options: {
                    bold: true,
                    color: 'success',
                  },
                },
              ],
            }}
          />
        </div>
      </div>

      <AnswerQuestionModal></AnswerQuestionModal>
    </>
  )
}

export {CompanyAdminStatistics}
