import React, {ReactNode} from 'react'

type Props = {
  label: {
    text: string
    tooltip?: {
      text?: string
      show?: boolean
    }
  }
  input?: {
    isRequired?: boolean
  }
  children: ReactNode
}

const SelectInput: React.FC<Props> = ({label, input, children}: Props) => {
  return (
    <div className='row'>
      <div className='col-md-4'>
        <label className='d-flex align-items-center fs-5 mt-2'>
          <span className={input?.isRequired ? 'required' : ''}>{label.text}</span>
          {label.tooltip?.show && (
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title={label.tooltip?.text}
            ></i>
          )}
        </label>
      </div>
      <div className='col-md-8'>
        <div className='mb-10 d-flex'>{children}</div>
      </div>
    </div>
  )
}

export {SelectInput}
