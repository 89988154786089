import React, {useState} from 'react'
import {ApexOptions} from 'apexcharts'
import {GraphTypeSelect} from '../dropdown/graph/GraphTypeSelect'
import {StrokeSelect} from '../dropdown/graph/StrokeSelect'
import {RadioInput} from '../form/RadioInput'
import {SelectInput} from '../form/SelectInput'
import {SwitchInput} from '../form/SwitchInput'
import {TextBox} from '../form/Textbox'
import {LineChart} from '../graph/Line'
import {PieChart} from '../graph/Pie'
import {BarChart} from '../graph/Bar'
import {AreaChart} from '../graph/Area'
import {DonutChart} from '../graph/Donut'
import {GraphPeriodSelect} from '../dropdown/graph/GraphPeriod'
import {YearSelect} from '../dropdown/YearSelect'
type Props = {
  className: any
  period: string
}

type GraphOption = {
  type?: string
  title?: {
    text: string
    align?: 'left' | 'right' | 'center'
  }
  labels?: string[]
  series?: any[]
  chart: {
    toolbar: {
      show: boolean
    }
  }
  colors?: string[]
  legend: {
    show: boolean
    position: 'left' | 'right'
  }
  dataLabels?: {
    enabled: boolean
  }
  stroke?: {
    curve?: string
  }
  xaxis: {
    categories?: string[]
    labels: {
      show?: boolean
    }
  }
  yaxis: {
    labels: {
      show?: boolean
    }
  }
  markers?: {
    colors?: string[]
    strokeColors?: string[]
    strokeWidth?: number
  }
}

const dummyData = (type: string, period: string) => {
  var data: any = {series: {}}

  switch (type) {
    case 'Line':
    case 'Bar':
    case 'Area':
      if (period === 'monthly') {
        data['series'] = [
          {name: 'Total GHC Emission', data: [30, 30, 50, 50, 35, 35, 70, 40, 30, 58, 11, 91]},
        ]
        data['categories'] = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ]

        return data
      } else if (period === 'quarterly') {
        data['series'] = [{name: 'Total GHC Emission', data: [30, 30, 50, 50]}]

        return data
      } else if (period === 'yearly') {
        data['series'] = [{name: 'Total GHC Emission', data: [100, 80]}]
        return data
      } else {
        return {series: [1, 2, 3, 3, 3, 3], labels: [1, 1, 1, 1, 1]}
      }
    case 'Pie':
    case 'Donut':
      if (period === 'monthly') {
        data['series'] = [30, 30, 50, 50, 35, 35, 70, 40, 30, 58, 11, 91]
        data['labels'] = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ]
        return data
      } else if (period === 'quarterly') {
        data['series'] = [30, 30, 50, 50]
        data['labels'] = ['Q1', 'Q2', 'Q3', 'Q4']
        return data
      } else if (period === 'yearly') {
        data['series'] = [100, 80]
        data['labels'] = ['2021', '2022']
        return data
      } else {
        return {series: [1, 2, 3, 3, 3, 3], labels: [1, 1, 1, 1, 1]}
      }
    // default:
    //   return []
  }
}

const generateGraph = (graphOption: GraphOption, period: string) => {
  const {type, ...option} = graphOption
  var data = dummyData(type ?? '', period)
  console.log(data)
  console.log(type)
  switch (type) {
    case 'Line':
      option.series = data.series ?? []
      option.xaxis.categories = data.categories ?? []
      return (
        <LineChart className='' chartHeight='400px' options={option as ApexOptions}></LineChart>
      )
    case 'Pie':
      option.series = data.series ?? []
      option.labels = data.labels ?? []
      return <PieChart className='' chartHeight='400px' options={option as ApexOptions}></PieChart>
    case 'Bar':
      option.series = data.series ?? []
      return <BarChart className='' chartHeight='400px' options={option as ApexOptions}></BarChart>
    case 'Area':
      option.series = data.series ?? []
      return (
        <AreaChart className='' chartHeight='400px' options={option as ApexOptions}></AreaChart>
      )
    case 'Donut':
      option.series = data.series ?? []
      option.labels = data.labels ?? []
      return (
        <DonutChart className='' chartHeight='400px' options={option as ApexOptions}></DonutChart>
      )
    default:
      return <></>
  }
}

const GraphGeneratorModal: React.FC<Props> = ({className = '', period}: Props) => {
  const chartWithAxis = ['Line', 'Bar', 'Area']
  const [graphPeriod, setGraphPeriod] = useState<string>(period)

  const [graph, setGraph] = useState<any>()

  const [graphOption, setGraphOption] = useState<GraphOption>({
    chart: {
      toolbar: {show: true},
    },
    legend: {show: false, position: 'left'},
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    // series: data.series, //[{name: 'Answered', data: [30, 30, 50, 50, 35, 35]}],
    // series: data.series, //[1, 2, 3, 4],
    // labels: data.labels, //['test', '1', '2', '3'],
  })

  function onGraphPeriodChange(period: string) {
    setGraphPeriod(period)
  }

  return (
    <div
      className='modal fade'
      id='modal_graph_generator'
      role='dialog'
      tabIndex={-1}
      aria-modal='true'
    >
      {/* begin::Modal dialog */}
      <div className='modal-dialog modal-dialog-scrollable modal-xl'>
        {/* begin::Modal content */}
        <div className='modal-content'>
          {/* begin::Modal body */}
          <div className='modal-body scroll-y mx-2 mx-xl-2'>
            <div className='row'>
              <div className='col-xxl-5'>
                <form
                // onSubmit={(e) => {
                //   e.preventDefault()
                //   const graph = generateGraph(graphOption, graphPeriod)
                //   setGraph(graph)
                // }}
                >
                  <div
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    className='px-9 hover-scroll-overlay-y pe-7 me-3 mb-2'
                    style={{maxHeight: 600}}
                  >
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-5 mb-2'>Graph Generator</label>
                    </div>

                    <div className='separator separator-content border-primary my-15 text-dark fw-bold'>
                      Period <i className='fa-solid fa-calendar-days text-primary fs-2 mx-2'></i>
                    </div>

                    <div className='mb-5 form-group'>
                      <GraphPeriodSelect
                        period={period}
                        onChange={(graphPeriod: string) => {
                          onGraphPeriodChange(graphPeriod)
                          console.log(graphPeriod)
                        }}
                      ></GraphPeriodSelect>
                      {(graphPeriod === 'monthly' || graphPeriod === 'quarterly') && (
                        <>
                          <div className='row mt-5'>
                            <div className='col-md-8'>
                              <YearSelect placeholder='Year' value='2022'></YearSelect>
                            </div>
                          </div>
                        </>
                      )}

                      {graphPeriod === 'yearly' && (
                        <>
                          <div className='row mt-5'>
                            <div className='col-md-6'>
                              <YearSelect placeholder='From Year' value='2021'></YearSelect>
                            </div>
                            <div className='col-md-6'>
                              <YearSelect placeholder='To Year' value='2022'></YearSelect>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className='separator separator-content border-primary my-15 text-dark fw-bold'>
                      Display <i className='fa-solid fa-gears text-primary fs-2 mx-2'></i>
                    </div>
                    <div className='row mb-5 form-group'>
                      <SelectInput label={{text: 'Graph type'}} input={{isRequired: true}}>
                        <GraphTypeSelect
                          onChange={(e: any) => {
                            setGraphOption({
                              ...graphOption,
                              type: e,
                            })
                          }}
                        ></GraphTypeSelect>
                      </SelectInput>
                      <div className=''></div>
                    </div>
                    {graphOption?.type && (
                      <>
                        <div className='mb-5 form-group'>
                          <TextBox
                            label={{text: 'Graph Title'}}
                            input={{
                              type: 'text',
                              isRequired: true,
                              onChange: (e: any) => {
                                setGraphOption({
                                  ...graphOption,
                                  title: {
                                    text: e,
                                  },
                                })
                              },
                            }}
                          ></TextBox>
                        </div>

                        {/* Legend */}
                        <fieldset>
                          <legend className='fs-3'>Legend</legend>
                          <div className='row'>
                            <div className='col'>
                              <SwitchInput
                                label={{text: 'Show'}}
                                options={[
                                  {
                                    name: 'legend_checkbox',
                                    id: 'legend_show',
                                  },
                                ]}
                                onChange={(e: any) => {
                                  const legend = graphOption?.legend
                                  legend.show = e
                                  setGraphOption({
                                    ...graphOption,
                                    legend,
                                  })
                                }}
                              ></SwitchInput>
                            </div>
                            {graphOption.legend.show && (
                              <div className='col'>
                                <div className='mb-5 form-group'>
                                  <RadioInput
                                    label={{text: 'Alignment'}}
                                    options={[
                                      {
                                        name: 'legend_alignment_radio',
                                        id: 'legend_left',
                                        value: 'left',
                                        label: 'Left',
                                      },
                                      {
                                        name: 'legend_alignment_radio',
                                        id: 'legend_right',
                                        value: 'right',
                                        label: 'Right',
                                      },
                                    ]}
                                    onChange={(e: any) => {
                                      const legend = graphOption?.legend
                                      legend.position = e
                                      setGraphOption({
                                        ...graphOption,
                                        legend,
                                      })
                                    }}
                                  ></RadioInput>
                                </div>
                              </div>
                            )}
                          </div>
                        </fieldset>

                        <fieldset>
                          <legend className='fs-3'>Settings</legend>
                          {/* X-Axis Y-Axis */}
                          {chartWithAxis.includes(graphOption.type) && (
                            <>
                              {' '}
                              <div className='row'>
                                <div className='col'>
                                  <SwitchInput
                                    label={{text: 'Show X-Axis Label'}}
                                    options={[
                                      {
                                        name: 'x-axis_show',
                                        id: 'x-axis_show',
                                      },
                                    ]}
                                    onChange={(e: any) => {
                                      const xaxis = graphOption.xaxis
                                      xaxis.labels.show = e
                                      setGraphOption({
                                        ...graphOption,
                                        xaxis,
                                      })
                                    }}
                                  ></SwitchInput>
                                </div>
                                <div className='col'>
                                  <SwitchInput
                                    label={{text: 'Show Y-Axis Label'}}
                                    options={[
                                      {
                                        name: 'y-axis_show',
                                        id: 'y-axis_show',
                                      },
                                    ]}
                                    onChange={(e: any) => {
                                      const yaxis = graphOption.yaxis
                                      yaxis.labels.show = e
                                      setGraphOption({
                                        ...graphOption,
                                        yaxis,
                                      })
                                    }}
                                  ></SwitchInput>
                                </div>
                              </div>
                              {(graphOption.type === 'Area' || graphOption.type === 'Line') && (
                                <div className='row'>
                                  <div className='col'>
                                    <SelectInput
                                      label={{text: 'Stroke style'}}
                                      input={{isRequired: false}}
                                    >
                                      <StrokeSelect
                                        onChange={(e: any) => {
                                          setGraphOption({
                                            ...graphOption,
                                            stroke: {
                                              curve: e,
                                            },
                                          })
                                        }}
                                      ></StrokeSelect>
                                    </SelectInput>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          {/* DataLabel */}
                          <div className='row'>
                            <div className='col'>
                              <SwitchInput
                                label={{text: 'Show Data Label'}}
                                options={[
                                  {
                                    name: 'data_label_show',
                                    id: 'data_label_show',
                                  },
                                ]}
                                onChange={(e: any) => {
                                  setGraphOption({
                                    ...graphOption,
                                    dataLabels: {
                                      enabled: e,
                                    },
                                  })
                                }}
                              ></SwitchInput>
                            </div>
                          </div>

                          {/* Stroke */}
                        </fieldset>
                      </>
                    )}
                  </div>

                  <div className='text-center mb-10'>
                    <button
                      type='reset'
                      className='btn btn-light me-3'
                      data-bs-dismiss='modal'
                      data-kt-users-modal-action='cancel'
                      onClick={() => {
                        setGraph(false)
                      }}
                    >
                      Discard
                    </button>

                    <a
                      id='graph_submit'
                      className='btn btn-primary'
                      onClick={() => {
                       
                        const graph = generateGraph(graphOption, graphPeriod)
                        setGraph(graph)
                      }}
                    >
                      Generate
                    </a>
                  </div>
                </form>
              </div>
              <div className='col-xxl-7'>
                {graph ? (
                  graph
                ) : (
                  <div>
                    <div className='text-center text-gray-400'>Graph preview area</div>
                    <div className='h-400px bg-light'></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {GraphGeneratorModal}
