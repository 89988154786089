import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(username: string, password: string) {
  var auth = {}
  if (username === 'superadmin' && password === '123123') {
    auth = {
      id: 1,
      api_token: 'superadmin',
      name: 'admin',
    }
  } else if (username === 'company' && password === '123123') {
    auth = {
      id: 2,
      api_token: 'company',
      name: 'company',
    }
  } else if (username === 'pic' && password === '123123') {
    auth = {
      id: 2,
      api_token: 'pic',
      name: 'pic',
    }
  } else if (username === 'reviewer' && password === '123123') {
    auth = {
      id: 2,
      rapi_tokenole: 'reviewer',
      name: 'reviewer',
    }
  } else if (username === 'approver' && password === '123123') {
    auth = {
      id: 2,
      roapi_tokenle: 'approver',
      name: 'approver',
    }
  } else {
    return {api_token: ''}
  }
  console.log(auth)

  return auth as AuthModel
  // return axios.post<AuthModel>(LOGIN_URL, {
  //   email,
  //   password,
  // })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  var user = {}

  if (token === 'superadmin') {
    user = {
      id: 1,
      role: 'superadmin',
      name: 'admin',
    }
  } else if (token === 'company') {
    user = {
      id: 2,
      role: 'company',
      name: 'company',
    }
  } else if (token === 'pic') {
    user = {
      id: 2,
      role: 'pic',
      name: 'pic',
    }
  } else if (token === 'reviewer') {
    user = {
      id: 2,
      role: 'reviewer',
      name: 'reviewer',
    }
  } else if (token === 'approver') {
    user = {
      id: 2,
      role: 'approver',
      name: 'approver',
    }
  } else {
    user = {
      id: 0,
      role: '',
      name: '',
    }
  }
  return user as UserModel
}
