/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {SuperAdminStatistic} from './components/Statistic'

const SuperAdminDashboard: FC = () => {
  return (
    <>
      <SuperAdminStatistic></SuperAdminStatistic>
    </>
  )
}

export {SuperAdminDashboard}
