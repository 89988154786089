import {Fragment} from 'react'
import date from 'date-and-time'

interface IndicatorWeeklyInputModal {
  month: string
  monthId: number
  weeks: string[]
}

const IndicatorWeeklyInput = (props: {weekRanges: IndicatorWeeklyInputModal[]}) => {
  return (
    <div className='row'>
      {props.weekRanges.reverse().map((d) => {
        return (
          <Fragment>
            <h5 className='mt-2'>{d.month}</h5>
            {d.weeks.map((week) => {
              return (
                <div className='col-md-3'>
                  <p className='text-gray-600 fw-bold'>{week}</p>
                  <input type='number' className='form-control'></input>
                </div>
              )
            })}
          </Fragment>
        )
      })}
    </div>
  )
}

export {IndicatorWeeklyInput as IndicatorWeeklyInput}
