import {TableExportButton} from '../../../../components/table-list/TableExportButton'
import {TableSearchInput} from '../../../../components/table-list/TableSearchInput'
import {useListView} from '../../../../services/user/ListViewProvider'
import {IndicatorTableHeaderFilter} from './IndicatorTableFilterButton'

const IndicatorTableHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <TableSearchInput placeHolder='Search Indicator'></TableSearchInput>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <IndicatorTableHeaderFilter />

          {/* begin::Export */}
          <TableExportButton></TableExportButton>
          {/* end::Export */}
        </div>

        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {IndicatorTableHeader}
