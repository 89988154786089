import { UserEditModalForm } from './AddTemplatemodalForm'
import {TemplateEditModalHeader} from './AddTemplateModalHeader'

const TemplateEditModal = () => {
  
  return (
    <>
      <div
        className='modal fade'
        id='kt_modal_add_template'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-scrollable mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <TemplateEditModalHeader />
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <UserEditModalForm></UserEditModalForm>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
     
      {/* end::Modal Backdrop */}
    </>
  )
}

export {TemplateEditModal}
