
const AssignUserComponent = () => {
  return (
    <>
      <div className='d-flex d-flex justify-content-end mb-10 mb-10'>
        <a data-bs-toggle='modal' data-bs-target='#kt_modal_users'>
          <div className='symbol-group symbol-hover flex-nowrap'>
            <div
              className='symbol symbol-30px symbol-circle mt-2'
              data-bs-toggle='tooltip'
              title='Alan Warden'
            >
              <span className='symbol-label fw-bold bg-warning text-inverse-warning'>A</span>
            </div>
            <div
              className='symbol symbol-30px symbol-circle mt-2'
              data-bs-toggle='tooltip'
              title='Susan Redwood'
            >
              <span className='symbol-label fw-bold bg-primary text-inverse-primary'>S</span>
            </div>
            <div
              className='symbol symbol-30px symbol-circle mt-2'
              data-bs-toggle='tooltip'
              title='Perry Matthew'
            >
              <span className='symbol-label fw-bold bg-danger text-inverse-danger'>P</span>
            </div>
          </div>
        </a>
        <a
          className='btn btn-sm fw-bold btn-light-primary mx-2'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_assign_user'
        >
          <span className='indicator-label'>
            <i className='bi bi-person-plus-fill fs-1'></i>
          </span>
        </a>
      </div>
    </>
  )
}

export {AssignUserComponent}
