export function RandomColor() {
    var randomcolor = ['success', 'info', 'warning', 'primary', 'danger']
    var randomIndex = randomIntFromInterval(0, 4)
    console.log(randomIndex)
    console.log()
    return randomcolor[randomIndex]
  }
  
  function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  }