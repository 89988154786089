/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PICStatistics} from './components/Statistic'

const PICDashboard: FC = () => {
  return (
    <>
      <PICStatistics></PICStatistics>
    </>
  )
}

export {PICDashboard}
