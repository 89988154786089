import {FC} from 'react'
import {CountCard} from '../../../../components/card/Count'
import {ProgressCard} from '../../../../components/card/Progress'
import {TabCard} from '../../../../components/card/Tab'
import {FillIndicatorModal} from '../../../../components/modal/AnswerIndicatorModal'
import {IndicatorForm} from '../../../../components/table-list/IndicatorForm'
import {LogList} from '../../../../components/list/LogList'
import { AnswerQuestionModal } from '../../../../components/modal/AnswerQuestionModal'

const PICStatistics: FC = () => {
  const indicatorTabs = [
    {
      title: 'Day',
      id: 'indicator_day_tab',
      children: (
        <IndicatorForm
          indicators={[
            {
              title: '20 Nov 2022',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
            {
              title: '21 Nov 2022',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
            {
              title: '22 Nov 2022',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
          ]}
        ></IndicatorForm>
      ),
    },
    {
      title: 'Week',
      id: 'indicator_week_tab',
      children: (
        <IndicatorForm
          indicators={[
            {
              title: 'Nov 1st - 7th (Week 1)',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
            {
              title: 'Nov 8th - 14th (Week 2)',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
            {
              title: 'Nov 15th - 21st (Week 3)',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
          ]}
        ></IndicatorForm>
      ),
    },
    {
      title: 'Month',
      id: 'indicator_month_tab',
      children: (
        <IndicatorForm
          indicators={[
            {
              title: 'Jan 2022',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
            {
              title: 'Feb 2022',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
            {
              title: 'March 2022',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
          ]}
        ></IndicatorForm>
      ),
    },
    {
      title: 'Quarter',
      id: 'indicator_quarter_tab',
      children: (
        <IndicatorForm
          indicators={[
            {
              title: 'Q1 2022',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
            {
              title: 'Q2 2022',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
            {
              title: 'Q3 2022',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
            {
              title: 'Q4 2022',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
          ]}
        ></IndicatorForm>
      ),
    },
    {
      title: 'Year',
      id: 'indicator_year_tab',
      children: (
        <IndicatorForm
          indicators={[
            {
              title: 'FYE 2021',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
            {
              title: 'FYE 2022',
              description: 'GHG emissions intensity by Revenue',
              category: 'GHG Emissions',
              unit: 'tonnes CO,e/RM million',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
            {
              title: 'FYE 2021',
              description: 'GHG emissions intensity by Production',
              category: 'GHG Emissions',
              unit: 'kg CO,e/BOE',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
            {
              title: 'FYE 2022',
              description: 'GHG emissions intensity by Production',
              category: 'GHG Emissions',
              unit: 'kg CO,e/BOE',
              submit: (indicator: any) => {
                console.log(indicator)
              },
            },
          ]}
        ></IndicatorForm>
      ),
    },
  ]

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'></div>
        <div className='col-xl-6'>
          <ProgressCard
            className='card-xl-stretch mb-xl-8'
            color='success'
            title='(65/100)'
            description='My Progress'
            progress='50%'
            showUser={false}
          />
        </div>
        <div className='col-xl-3'></div>
      </div>

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <CountCard
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen032.svg'
            color='white'
            iconColor='primary'
            title='64'
            description='Answered'
          />
        </div>

        <div className='col-xl-3'>
          <CountCard
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
            color='white'
            iconColor='success'
            title='38'
            description='Reviewed'
          />
        </div>

        <div className='col-xl-3'>
          <CountCard
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/finance/fin006.svg'
            color='white'
            iconColor='warning'
            title='21'
            description='Approved'
          />
        </div>

        <div className='col-xl-3'>
          <CountCard
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='white'
            iconColor='info'
            title='2'
            description='Pending'
          />
        </div>
      </div>
      {/* end::Row */}

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <div className='card card-xxl-stretch mb-5 mb-xl-10'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>My Pending Question</span>
              </h3>
            </div>
            <div className='card-body card-scroll h-350px ml-2'>
              <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              >
                <tbody className='text-gray-600 fw-bold'>
                  <tr>
                    <td>1.</td>
                    <td>
                      Please list the firm’s three primary risks related to climate change and if
                      any, the firm´s climate-related opportunities
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>
                      How are purchases and waste managed? If the firm rely on any scarce resources,
                      please describe what efforts are made to mitigate the risk of those resources
                      becoming scarcer in the future, e.g. recycling, substitutes, or improved
                      resource efficiency?
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td>
                      Please list the firm’s (1-2) primary means of making a positive environmental
                      impact or minimising negative environmental impact
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>4.</td>
                    <td>
                      Provide an overview of your business and the market environment in which you
                      operate to contextualise your reporting for the reader.
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>5.</td>
                    <td>
                      Does the firm have a history of accidents? If so, how have these been managed?
                      Are there any preventive measures, such as policies?
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>6.</td>
                    <td>
                      Are your employees inspired by your efforts to become a more climate
                      conscious, socially responsible, ethically led organization?
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>7.</td>
                    <td>
                      Please state your targets for gender and cultural equality and indicate the
                      relative split of men/women at every level of the firm, particularly the Board
                      of Directors and management team
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>8.</td>
                    <td>
                      Please list the firm’s three primary risks related to climate change and if
                      any, the firm´s climate-related opportunities
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>9.</td>
                    <td>
                      Does the firm have a history of accidents? If so, how have these been managed?
                      Are there any preventive measures, such as policies?
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-sm fw-bold btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_answer_question'
                      >
                        Answer
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>10.</td>
                    <td>
                      How are purchases and waste managed? If the firm rely on any scarce resources,
                      please describe what efforts are made to mitigate the risk of those resources
                      becoming scarcer in the future, e.g. recycling, substitutes, or improved
                      resource efficiency?
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='col-xl-6'>
          <div></div>
          <TabCard
            title='Active Indicator'
            description='Express indicator filling'
            tabs={indicatorTabs}
          ></TabCard>
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <LogList
            className='card-xxl-stretch mb-5 mb-xl-10'
            logOptions={{
              title: 'Activities',
              description: '78 Questions',
              data: [
                {
                  title: 'Answered Question',
                  options: {
                    bold: false,
                    color: 'warning',
                  },
                },
                {
                  title:
                    'Question XXXX <span class="badge badge-light-success">Approved</span> by<a href="/apps/profile/overview" class="text-primary">#Xandier9910</a>',
                  // { ReactHtmlParser('')},
                  // {parseHtml('<h1>Hello html-react-parser</h1>')},

                  options: {
                    bold: true,
                    color: 'success',
                  },
                },
                {
                  title:
                    'Reviewer Ben99910 <span class="badge badge-light-warning">commented</span>  on question 1',
                  options: {
                    bold: true,
                    color: 'error',
                  },
                },
                {
                  title:
                    'Reviewer Ben99910 <span class="badge badge-light-warning">commented</span>  on question 1',
                  options: {
                    bold: true,
                    color: 'error',
                  },
                },
                {
                  title:
                    'Question XXXX <span class="badge badge-light-success">Approved</span> by<a href="/apps/profile/overview" class="text-primary">#kin810</a>',
                  options: {
                    bold: true,
                    color: 'success',
                  },
                },
                {
                  title:
                    'Question XXXX <span class="badge badge-light-success">Approved</span> by<a href="/apps/profile/overview" class="text-primary">#Xandier9910</a>',
                  // { ReactHtmlParser('')},
                  // {parseHtml('<h1>Hello html-react-parser</h1>')},
                  options: {
                    bold: true,
                    color: 'success',
                  },
                },
                {
                  title:
                    'Reviewer Ben99910 <span class="badge badge-light-warning">commented</span>  on question 1',
                  options: {
                    bold: true,
                    color: 'error',
                  },
                },
                {
                  title: 'Answered Question',
                  options: {
                    bold: false,
                    color: 'warning',
                  },
                },
                {
                  title:
                    'Reviewer Ben99910 <span class="badge badge-light-warning">commented</span>  on question 1',
                  options: {
                    bold: true,
                    color: 'error',
                  },
                },
                {
                  title:
                    'Question XXXX <span class="badge badge-light-success">Approved</span> by<a href="/apps/profile/overview" class="text-primary">#Xandier9910</a>',
                  // { ReactHtmlParser('')},
                  // {parseHtml('<h1>Hello html-react-parser</h1>')},
                  options: {
                    bold: true,
                    color: 'success',
                  },
                },
                {
                  title:
                    'Question XXXX <span class="badge badge-light-success">Approved</span> by<a href="/apps/profile/overview" class="text-primary">#Xandier9910</a>',
                  // { ReactHtmlParser('')},
                  // {parseHtml('<h1>Hello html-react-parser</h1>')},
                  options: {
                    bold: true,
                    color: 'success',
                  },
                },
              ],
            }}
          />
        </div>
      </div>

      <AnswerQuestionModal></AnswerQuestionModal>
      <FillIndicatorModal></FillIndicatorModal>
    </>
  )
}

export {PICStatistics}
