import {Pagination} from 'react-bootstrap'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {ListLoading} from '../../../../../components/loading/ListLoading'
import {TemplateTableActionCell} from './TemplateTableActionCell'

const TemplateTable = () => {
  const isLoading = false

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th></th>
              <th className='min-w-125px'>Question</th>
              <th></th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            <tr>
              <td>1.</td>
              <td>
                Please list the firm’s three primary risks related to climate change and if any, the
                firm´s climate-related opportunities
              </td>
              <td>
                <TemplateTableActionCell id={undefined}></TemplateTableActionCell>
              </td>
            </tr>
            <tr>
              <td>2.</td>
              <td>
                How are purchases and waste managed? If the firm rely on any scarce resources,
                please describe what efforts are made to mitigate the risk of those resources
                becoming scarcer in the future, e.g. recycling, substitutes, or improved resource
                efficiency?
              </td>
              <td>
                <TemplateTableActionCell id={undefined}></TemplateTableActionCell>
              </td>
            </tr>
            <tr>
              <td>3.</td>
              <td>
                Please list the firm’s (1-2) primary means of making a positive environmental impact
                or minimising negative environmental impact
              </td>
              <td>
                <TemplateTableActionCell id={undefined}></TemplateTableActionCell>
              </td>
            </tr>
            <tr>
              <td>4.</td>
              <td>
                Provide an overview of your business and the market environment in which you operate
                to contextualise your reporting for the reader.
              </td>
              <td>
                <TemplateTableActionCell id={undefined}></TemplateTableActionCell>
              </td>
            </tr>
            <tr>
              <td>5.</td>
              <td>
                Does the firm have a history of accidents? If so, how have these been managed? Are
                there any preventive measures, such as policies?
              </td>
              <td>
                <TemplateTableActionCell id={undefined}></TemplateTableActionCell>
              </td>
            </tr>
            <tr>
              <td>6.</td>
              <td>
                Are your employees inspired by your efforts to become a more climate conscious,
                socially responsible, ethically led organization?
              </td>
              <td>
                <TemplateTableActionCell id={undefined}></TemplateTableActionCell>
              </td>
            </tr>
            <tr>
              <td>7.</td>
              <td>
                Please state your targets for gender and cultural equality and indicate the relative
                split of men/women at every level of the firm, particularly the Board of Directors
                and management team
              </td>
              <td>
                <TemplateTableActionCell id={undefined}></TemplateTableActionCell>
              </td>
            </tr>
            <tr>
              <td>8.</td>
              <td>
                Please list the firm’s three primary risks related to climate change and if any, the
                firm´s climate-related opportunities
              </td>
              <td>
                <TemplateTableActionCell id={undefined}></TemplateTableActionCell>
              </td>
            </tr>
            <tr>
              <td>9.</td>
              <td>
                Does the firm have a history of accidents? If so, how have these been managed? Are
                there any preventive measures, such as policies?
              </td>
              <td>
                <TemplateTableActionCell id={undefined}></TemplateTableActionCell>
              </td>
            </tr>

            <tr>
              <td>10.</td>
              <td>
                How are purchases and waste managed? If the firm rely on any scarce resources,
                please describe what efforts are made to mitigate the risk of those resources
                becoming scarcer in the future, e.g. recycling, substitutes, or improved resource
                efficiency?
              </td>
              <td>
                <TemplateTableActionCell id={undefined}></TemplateTableActionCell>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export {TemplateTable}
