import {KTCardBody} from '../../../../../../_metronic/helpers'
import {AccordionQuestionItem} from '../../../../../components/accordion/QuestionAnswerAccordion'
import {AssignUserModal} from '../../../../../components/modal/AssignUserModal'

const ViewQuestionTable = () => {
  const isLoading = false

  return (
    <KTCardBody className='py-4'>
      <div className='accordion' id='kt_accordion_question'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <tbody className='text-gray-600 fw-bold'>
              <tr>
                <td>
                  <AccordionQuestionItem
                    question=' Please list the firm’s three primary risks related to climate change and if any, the firm´s
                        climate-related opportunities'
                    answer=' ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.


                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.

                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.'
                    number={1}
                    status='In Progress'
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <AccordionQuestionItem
                    question='How are purchases and waste managed? If the firm rely on any scarce
                  resources, please describe what efforts are made to mitigate the risk of those resources
                  becoming scarcer in the future, e.g. recycling, substitutes, or improved resource efficiency?'
                    answer=' ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.


                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.

                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.'
                    number={2}
                    status='Answered'
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <AccordionQuestionItem
                    question='Please list the firm’s (1-2) primary means of making a positive environmental impact or
                  minimising negative environmental impact'
                    answer=' ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.


                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.

                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.'
                    number={3}
                    status='Reviewed'
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <AccordionQuestionItem
                    question=' Provide an overview of your business and the market environment in which you
                  operate to contextualise your reporting for the reader.'
                    answer=' ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.


                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.

                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.'
                    number={4}
                    status='Approved'
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <AccordionQuestionItem
                    question='    Does the firm have a history of accidents? If so, how have these been managed? Are there
                  any preventive measures, such as policies?'
                    answer=' ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.


                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.

                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.'
                    number={5}
                    status='Adjust'
                    comment={true}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <AccordionQuestionItem
                    question='Are your employees inspired by your efforts to become a more climate conscious, socially responsible, ethically led organization?'
                    answer=' ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.


                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.

                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.'
                    number={6}
                    status='In Progress'
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <AccordionQuestionItem
                    question=' Please state your targets for gender and cultural equality and indicate the
                  relative split of men/women at every level of the firm, particularly the Board of Directors
                  and management team'
                    answer=' ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.


                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.

                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.'
                    number={7}
                    status='In Progress'
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <AccordionQuestionItem
                    question='  Please list the firm’s three primary risks related to climate change and if any, the firm´s
                  climate-related opportunities'
                    answer=' ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.


                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.

                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.'
                    number={8}
                    status='In Progress'
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <AccordionQuestionItem
                    question='  Does the firm have a history of accidents? If so, how have these been managed? Are there
                  any preventive measures, such as policies?'
                    answer=' ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.


                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.

                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.'
                    number={9}
                    status='In Progress'
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <AccordionQuestionItem
                    question=' How are purchases and waste managed? If the firm rely on any scarce
                  resources, please describe what efforts are made to mitigate the risk of those resources
                  becoming scarcer in the future, e.g. recycling, substitutes, or improved resource efficiency?'
                    answer=' ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.


                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.

                    ESG reporting is the disclosure of environmental, social and corporate governance data. As with all disclosures, its purpose is to shed light on a company’s ESG activities while improving investor transparency and inspiring other organizations to do the same. Reporting is also an effective way to demonstrate that you’re meeting goals and that your ESG projects are genuine — not just greenwashing, empty promises, or lip service.

                    Since ESG reports summarize the qualitative and quantitative benefits of a company’s ESG activities, investors can screen investments, align investments to their values, and avoid companies with the risk of environmental damage, social missteps or corruption.'
                    number={10}
                    status='Approved'
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <AssignUserModal />
    </KTCardBody>
  )
}

export {ViewQuestionTable}
