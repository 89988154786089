import {Fragment} from 'react'
import date from 'date-and-time'

const IndicatorYearlyInputModal = (props: {years: number[]}) => {
  return (
    <div className='row'>
      {props.years.reverse().map((d) => {
        return (
          <div className='col-md-3'>
            <p className='text-gray-600 fw-bold'>{d}</p>
            <input type='number' className='form-control'></input>
          </div>
        )
      })}
    </div>
  )
}

export {IndicatorYearlyInputModal }
