import DateRangePicker from 'react-bootstrap-daterangepicker'
import {KTCard, KTCardBody} from '../../../../_metronic/helpers'
import {DateRangePickerBootstrap} from '../../../components/date/DateRangePicker'
import {IndicatorDailyInput} from '../../../components/form/IndicatorDailyInput'
import {IndicatorMonthlyInput} from '../../../components/form/IndicatorMonthlyInput'
import {IndicatorQuarterlyInputModal} from '../../../components/form/IndicatorQuarterlyInput'
import {IndicatorWeeklyInput} from '../../../components/form/IndicatorWeeklyInput'
import {IndicatorYearlyInputModal} from '../../../components/form/IndicatorYearlyInput'
import {AssignUserModal} from '../../../components/modal/AssignUserModal'
import {UserModal} from '../../../components/modal/UserModal'
import {IndicatorTable} from '../../../components/table-list/indicatorTable'
import {TableExportButton} from '../../../components/table-list/TableExportButton'
import {TablePagination} from '../../../components/table-list/TablePagination'
import {TableSearchInput} from '../../../components/table-list/TableSearchInput'
import {PageLink, PageTitle} from '../../../layout/core'
import {UsersListHeader} from '../../user/components/header/UsersListHeader'
import {AssignUserComponent} from './component/AssignUserComponent'
import {IndicatorTableHeaderFilter} from './component/IndicatorTableFilterButton'
import {IndicatorTableHeader} from './component/IndicatorTableHeader'
import date from 'date-and-time'
import { SearchComponent } from '../../../assets/ts/components'
const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Indicator',
    path: '/apps/indicator/progress',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const dateRanges = () => {
  var startDate = new Date('2022-01-01')
  var endDate = new Date()
  var loopDate = startDate
  var dateRanges: Date[] = []
  while (loopDate <= endDate) {
    dateRanges.push(loopDate)
    loopDate = date.addDays(loopDate, 1)
  }

  return dateRanges
}
const weekRanges = () => {
  var weekRanges = [
    {
      month: 'November',
      monthId: 11,
      weeks: [
        'Nov 7th - 13th (Week 1)',
        'Nov 14th - 20th (Week 2)',
        'Nov 21st - 27th (Week 3)',
        'Nov 28th - Dec 4th (Week 4)',
      ],
    },
    {
      month: 'December',
      monthId: 12,
      weeks: ['Dec 5th - 11th (Week 1)', 'Dec 12th - 18th (Week 2)'],
    },
  ]
  return weekRanges
}
const years = [2021, 2022]

const indicators = [
  {
    mainIndicators: [
      {
        id: 1,
        indicator: 'GHG emission intensity By Revenue',
        unit: 'tonnes CO2/RM million',
        subIndicators: [],
        data: [100, 200, 300],
      },
      {
        id: 2,
        indicator: 'GHG emission intensity By Generation',
        unit: 'kg CO2/MWh',
        subIndicators: [],
        data: [100, 200, 300],
      },
      {
        id: 3,
        indicator: 'GHG emission intensity By Production',
        unit: 'kg CO2/MWh',
        subIndicators: [],
        data: [100, 200, 300],
      },
    ],
    title: 'GHG Emissions',
    id: 1,
  },

  {
    mainIndicators: [
      {
        id: 4,
        indicator: 'Direct GHG emissions',
        unit: 'tonnes CO2',
        subIndicators: [
          {id: 1, indicator: 'Carbon Dioxide', unit: 'tonnes', data: [100, 200, 300]},
          {id: 2, indicator: ' Dioxide', unit: 'tonnes', data: [100, 200, 300]},
        ],
        data: [100, 200, 300],
      },
    ],
    title: 'Scope 1 : Direct GHG emissions',
    id: 2,
  },
]

const dates = dateRanges()

const AssignIndicatorPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}> Assign Indicator </PageTitle>

      {/* begin::Col */}
      <div className='col-md-12 p-0'>
        <KTCard>
          <div className='card'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Assign Indicator</span>
              </h3>
              {/* begin::Card toolbar */}
              <div className='card-toolbar w-100'>
                {/* begin::Group actions */}
                <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                  <li className='nav-item'>
                    <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_daily'>
                      Daily
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_weekly'>
                      Weekly
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_monthly'>
                      Monthly
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_quarterly'>
                      Quarterly
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_yearly'>
                      Yearly
                    </a>
                  </li>
                </ul>
                {/* end::Group actions */}
              </div>
              {/* end::Card toolbar */}

              <div className='card-body card-scroll h-850px p-0'>
                <TableSearchInput placeHolder='Search Indicator'></TableSearchInput>
                <div className='tab-content' id='myTabContent'>
                  <div className='tab-pane fade active show' id='kt_tab_daily' role='tabpanel'>
                    <div className='mb-10'>
                      <label className='form-label fs-6 fw-bold'>Date:</label>
                      <DateRangePickerBootstrap></DateRangePickerBootstrap>
                    </div>
                    <IndicatorTable
                      indicators={indicators}
                      action={<AssignUserComponent></AssignUserComponent>}
                    >
                      <IndicatorDailyInput dates={dates}></IndicatorDailyInput>
                    </IndicatorTable>
                  </div>

                  <div className='tab-pane fade' id='kt_tab_weekly' role='tabpanel'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>From Year:</label>
                          <select className='form-control'>
                            <option selected> 2022 - January </option>
                            <option> 2022 - Febraury </option>
                            <option> 2022 - March </option>
                            <option> 2022 - April </option>
                            <option> 2022 - May </option>
                            <option> 2022 - June </option>
                            <option> 2022 - July </option>
                            <option> 2022 - August </option>
                            <option> 2022 - September </option>
                            <option> 2022 - October </option>
                            <option> 2022 - November </option>
                            <option> 2022 - December </option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>To Year:</label>
                          <select className='form-control'>
                            <option> 2022 - January </option>
                            <option> 2022 - Febraury </option>
                            <option> 2022 - March </option>
                            <option> 2022 - April </option>
                            <option> 2022 - May </option>
                            <option> 2022 - June </option>
                            <option> 2022 - July </option>
                            <option> 2022 - August </option>
                            <option> 2022 - September </option>
                            <option> 2022 - October </option>
                            <option> 2022 - November </option>
                            <option selected> 2022 - December </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <IndicatorTable
                      indicators={indicators}
                      action={<AssignUserComponent></AssignUserComponent>}
                    >
                      <IndicatorWeeklyInput weekRanges={weekRanges()}></IndicatorWeeklyInput>
                    </IndicatorTable>
                  </div>

                  <div className='tab-pane fade' id='kt_tab_monthly' role='tabpanel'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>From Year:</label>
                          <select className='form-control'>
                            <option> 2021 </option>
                            <option selected> 2022 </option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>To Year:</label>
                          <select className='form-control'>
                            <option> 2021 </option>
                            <option selected> 2022 </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <IndicatorTable
                      indicators={indicators}
                      action={<AssignUserComponent></AssignUserComponent>}
                    >
                      <IndicatorMonthlyInput years={years}></IndicatorMonthlyInput>
                    </IndicatorTable>
                    <div></div>
                  </div>
                  <div className='tab-pane fade' id='kt_tab_quarterly' role='tabpanel'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>From Year:</label>
                          <select className='form-control'>
                            <option> 2021 </option>
                            <option selected> 2022 </option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>To Year:</label>
                          <select className='form-control'>
                            <option> 2021 </option>
                            <option selected> 2022 </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <IndicatorTable
                      indicators={indicators}
                      action={<AssignUserComponent></AssignUserComponent>}
                    >
                      <IndicatorQuarterlyInputModal years={years}></IndicatorQuarterlyInputModal>
                    </IndicatorTable>
                  </div>
                  <div className='tab-pane fade' id='kt_tab_yearly' role='tabpanel'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>From Year:</label>
                          <select className='form-control'>
                            <option> 2021 </option>
                            <option selected> 2022 </option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>To Year:</label>
                          <select className='form-control'>
                            <option> 2021 </option>
                            <option selected> 2022 </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <IndicatorTable
                      indicators={indicators}
                      action={<AssignUserComponent></AssignUserComponent>}
                    >
                      <IndicatorYearlyInputModal years={years}></IndicatorYearlyInputModal>
                    </IndicatorTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </KTCard>
      </div>
      {/* end::Col */}
      <AssignUserModal></AssignUserModal>
      <UserModal></UserModal>
    </>
  )
}

export {AssignIndicatorPage}
