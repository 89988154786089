/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import Select from 'react-select'

const options = [
  {value: '1', label: 'GHG Emissions'},
  {value: '2', label: 'Scope 1 : Direct GHG emissions'},
]

const IndicatorTitleSelect = () => <Select options={options} />

export {IndicatorTitleSelect}
