/* eslint-disable jsx-a11y/anchor-is-valid */
import {CompanySelect} from '../../../../components/dropdown/CompanySelect'
import {StepProps} from '../../../../modals/indicator'
import {MonthSelect} from '../../../../components/dropdown/MonthSelect'
import {QuarterSelect} from '../../../../components/dropdown/QuarterSelect'
import {YearSelect} from '../../../../components/dropdown/YearSelect'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Company Name</span>
          </label>
          <CompanySelect></CompanySelect>
        </div>
        {/*end::Form Group */}

        {/*begin::Form Group */}
        <div className='fv-row'>
          {/* begin::Label */}
          <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>Period</span>

            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Period to input indicator value'
            ></i>
          </label>
          {/* end::Label */}
          <div className='mb-10'>
            <div className='form-check-inline '>
              <input
                className='form-check-input mx-5'
                type='radio'
                name='inlineRadioOptions'
                id='periodDay'
                value='daily'
                onChange={(e) => {
                  updateData({indicatorPeriod: e.currentTarget.value})
                }}
              />
              <label className='form-check-label'>Daily</label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input mx-5'
                type='radio'
                name='inlineRadioOptions'
                id='periodMonth'
                value='monthly'
                onChange={(e) => {
                  updateData({indicatorPeriod: e.currentTarget.value})
                }}
              />
              <label className='form-check-label'>Monthly</label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input mx-5'
                type='radio'
                name='inlineRadioOptions'
                id='periodQuarter'
                value='quarterly'
                onChange={(e) => {
                  updateData({indicatorPeriod: e.currentTarget.value})
                }}
              />
              <label className='form-check-label'>Quarterly</label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input mx-5'
                type='radio'
                name='inlineRadioOptions'
                id='periodYear'
                value='yearly'
                onChange={(e) => {
                  updateData({indicatorPeriod: e.currentTarget.value})
                }}
              />
              <label className='form-check-label'>Yearly</label>
            </div>
          </div>
        </div>

        <div className='mb-10'>
          {data.indicatorPeriod === 'daily' && (
            <div className='form-check-inline mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>Starting on</span>
              </label>
              <input
                type='date'
                className='form-control form-control-lg'
                name='startOn'
                placeholder=''
                // value={data.mainIndicator}
                // onChange={(e) =>
                //   updateData({
                //     mainIndicator: e.target.value,
                //   })
                // }
              />
            </div>
          )}

          <div className='row'>
            {['monthly'].includes(data.indicatorPeriod) && (
              <div className='col-3'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Starting Month</span>
                </label>
                <MonthSelect></MonthSelect>
              </div>
            )}

            {['quarterly'].includes(data.indicatorPeriod) && (
              <div className='col-3'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Starting Quarter</span>
                </label>
                <QuarterSelect></QuarterSelect>
              </div>
            )}

            {['yearly', 'monthly', 'quarterly'].includes(data.indicatorPeriod) && (
              <div className='col-3'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Starting Year</span>
                </label>
                <YearSelect placeholder='year' value='2021'></YearSelect>
              </div>
            )}
          </div>
        </div>
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export {Step1}
