/* eslint-disable jsx-a11y/anchor-is-valid */
import {StepProps} from '../../../../modals/indicator'

const Step3 = ({data}: StepProps) => {
  return (
    <>
      <div data-kt-stepper-element='content'>
        <div className='w-100 text-center'>
          {/* begin::Heading */}
          <h1 className='fw-bold text-dark mb-3'>Review</h1>
          {/* end::Heading */}

          {/* begin::Description */}
          <div className='fw-semibold fs-3'>Please have a look on your new indicator.</div>
          {/* end::Description */}

          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              <table className='w-100'>
                <thead className='bg-active-primary text-white active'>
                  <tr style={{textAlign: 'left'}}>
                    <th className='w-250px'>PERFORMANCE INDICATOR</th>
                    <th className='w-150px'>UNITS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='bg-success text-white fw-bold' style={{textAlign: 'left'}}>
                    <td colSpan={4}>GHG Emissions</td>
                  </tr>
                  <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                    <td>Total GHG Emissions</td>
                    <td>tonnes CO2</td>
                  </tr>
                  <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                    <td>GHG emission intensity By Revenue</td>
                    <td>tonnes CO2/RM million</td>
                  </tr>
                  <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                    <td>GHG emission intensity By Generation</td>
                    <td>kg CO2/MWh</td>
                  </tr>
                  <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                    <td>GHG emission intensity By Production</td>
                    <td>kg CO2/BOE</td>
                  </tr>

                  <tr className='bg-success text-white fw-bold' style={{textAlign: 'left'}}>
                    <td colSpan={4}>Scope 1 : Direct GHG emissions</td>
                  </tr>
                  <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                    <td>Total direct GHG Emissions (Scope 1)</td>
                    <td>tonnes </td>
                  </tr>
                  <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                    <td className='px-7'>Carbon Dioxide</td>
                    <td>tonnes </td>
                  </tr>
                  <tr className='bg-light-primary hoverable mx-5' style={{textAlign: 'left'}}>
                    <td className='px-7'>Methane </td>
                    <td>tonnes </td>
                  </tr>
                  <tr className='bg-light-primary hoverable my-5' style={{textAlign: 'left'}}>
                    <td className='px-7'>Nitrous Oxide</td>
                    <td>tonnes </td>
                  </tr>

                  <tr className='bg-success text-white fw-bold' style={{textAlign: 'left'}}>
                    <td colSpan={4}>GHG Emissions</td>
                  </tr>
                  <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                    <td>Total GHG Emissions</td>
                    <td>tonnes CO2</td>
                  </tr>
                  <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                    <td>GHG emission intensity By Revenue</td>
                    <td>tonnes CO2/RM million</td>
                  </tr>
                  <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                    <td>GHG emission intensity By Generation</td>
                    <td>kg CO2/MWh</td>
                  </tr>
                  <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                    <td>GHG emission intensity By Production</td>
                    <td>kg CO2/BOE</td>
                  </tr>

                  <tr className='bg-success text-white fw-bold' style={{textAlign: 'left'}}>
                    <td colSpan={4}>GHG Emissions</td>
                  </tr>
                  <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                    <td>Total GHG Emissions</td>
                    <td>tonnes CO2</td>
                  </tr>
                  <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                    <td>GHG emission intensity By Revenue</td>
                    <td>tonnes CO2/RM million</td>
                  </tr>
                  <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                    <td>GHG emission intensity By Generation</td>
                    <td>kg CO2/MWh</td>
                  </tr>
                  <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                    <td>GHG emission intensity By Production</td>
                    <td>kg CO2/BOE</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* end::Table container */}
          </div>

          <div className='row' style={{float: 'right'}}>
            <div className='col-md-12 mt-2'>
              <div className='mb-10'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='flexSwitchDefault'
                  />
                  <label className='form-check-label' htmlFor='flexSwitchDefault'>
                    Save this as new template
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Step3}
