/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {ReactNode} from 'react'

type Tab = {
  title: string
  id: string
  children: ReactNode
}
type Props = {
  className?: string
  title: string
  description: string
  tabs: Tab[]
}

const TabCard: React.FC<Props> = ({className, title, description, tabs = []}) => {
  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{description}</span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            {tabs.map((tab, index) => {
              return (
                <li className='nav-item' key={tab.id}>
                  <a
                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
                      index === 0 && 'active'
                    }`}
                    data-bs-toggle='tab'
                    href={`#${tab.id}`}
                  >
                    {tab.title}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 card-scroll h-350px ml-2'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          {tabs.map((tab, index) => {
            return (
              <div
                className={`tab-pane fade show ${index === 0 && 'active'}`}
                id={tab.id}
                key={`item_${tab.id}`}
              >
                {tab.children}
              </div>
            )
          })}
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TabCard}
