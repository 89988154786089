import {FC} from 'react'
import clsx from 'clsx'
import {toAbsoluteUrl, WithChildren} from '../../../_metronic/helpers'
import {AssignUserComponent} from '../../pages/indicator/assign/component/AssignUserComponent'

// Wrapper on html card:
// https://preview.keenthemes.com/metronic8/demo1/documentation/base/cards.html

type Props = {
  status?: string
  question: string
  answer: string
  number: number
  comment?: boolean
}

type Comment = {
  reviewer: string
  comment: string
}

const GetStatus = ({status = ''}) => {
  if (status === 'In Progress')
    return <span className='badge badge-light-warning'>In Progress</span>
  else if (status === 'Answered') return <span className='badge badge-light-info'>Answered</span>
  else if (status === 'Reviewed') return <span className='badge badge-light-primary'>Reviewed</span>
  else if (status === 'Approved') return <span className='badge badge-light-success'>Approved</span>
  else if (status === 'Adjust') return <span className='badge badge-light-danger'>Adjust</span>
  else return <></>
}

const ReviewerComments = ({comment = false}) => {
  if (comment === false) return <></>
  else {
    return (
      <div className='card bg-light-danger card-xl-stretch mb-xl-8 mt-6'>
        <div className='card-body my-3'>
          <a href='#' className='card-title fw-bold text-danger fs-5 mb-3 d-block'>
            Reviewer's Comment
          </a>

          {/* begin::Replies */}
          <div className='mb-7 ps-10'>
            {/* begin::Reply */}
            <div className='d-flex mb-5'>
              {/* begin::Info */}
              <div className='d-flex flex-column flex-row-fluid'>
                {/* begin::Info */}
                <div className='d-flex align-items-center flex-wrap mb-1'>
                  <a href='#' className='text-gray-800 text-hover-primary fw-bold me-2'>
                    Alice Danchik
                  </a>

                  <span className='text-gray-400 fw-semibold fs-7'>1 day</span>
                </div>
                {/* end::Info */}

                {/* begin::Post */}
                <span className='text-gray-800 fs-7 fw-normal pt-1'>
                  Long before you sit dow to put digital pen to paper you need to make sure you have
                  to sit down and write.
                </span>
                {/* end::Post */}
              </div>
              {/* end::Info */}
            </div>
            {/* end::Reply */}

            {/* begin::Reply */}
            <div className='d-flex'>
              {/* begin::Info */}
              <div className='d-flex flex-column flex-row-fluid'>
                {/* begin::Info */}
                <div className='d-flex align-items-center flex-wrap mb-1'>
                  <a href='#' className='text-gray-800 text-hover-primary fw-bold me-2'>
                    Harris Bold
                  </a>

                  <span className='text-gray-400 fw-semibold fs-7'>2 days</span>
                </div>
                {/* end::Info */}

                {/* begin::Post */}
                <span className='text-gray-800 fs-7 fw-normal pt-1'>
                  Outlines keep you honest. They stop you from indulging in poorly
                </span>
                {/* end::Post */}
              </div>
              {/* end::Info */}
            </div>
            {/* end::Reply */}
          </div>
          {/* end::Replies */}
        </div>
      </div>
    )
  }
}

const AccordionQuestionItem: FC<Props & WithChildren> = (props) => {
  const {status, question, answer, number, comment} = props
  return (
    <div>
      <a href='#' data-bs-toggle='collapse' data-bs-target={'#kt_accordion_1_body_' + number}>
        <span className='badge badge-light-dark'>Question {number} ：</span>

        <GetStatus status={status}></GetStatus>

        <br></br>
        <span className='text-gray-700 fw-bolder gs-0'>{question}</span>
      </a>
      <AssignUserComponent></AssignUserComponent>
      <div
        id={'kt_accordion_1_body_' + number}
        className='accordion-collapse collapse'
        aria-labelledby='kt_accordion_1_header_1'
        data-bs-parent='#kt_accordion_1'
      >
        <div className='accordion-body'>
          <div className='card bg-light-primary card-xl-stretch mb-xl-8'>
            <div className='card-body my-3'>
              <a href='#' className='card-title fw-bold text-primary fs-5 mb-3 d-block'>
                PIC's Answer
              </a>
              <div className='mb-7 ps-10'>{answer}</div>{' '}
            </div>
          </div>
          <ReviewerComments comment={comment} />
          <div className='symbol-group symbol-hover flex-nowrap'></div>
        </div>
      </div>
    </div>
  )
}

export {AccordionQuestionItem}
