import {Fragment} from 'react'
import date from 'date-and-time'

interface IndicatorMonhtlyInputModal {
  month: string
  monthId: number
}

const months = [
  {
    month: 'January',
    monthId: 1,
  },
  {
    month: 'February',
    monthId: 1,
  },
  {
    month: 'March',
    monthId: 1,
  },
  {
    month: 'April',
    monthId: 1,
  },
  {
    month: 'May',
    monthId: 1,
  },
  {
    month: 'June',
    monthId: 1,
  },
  {
    month: 'July',
    monthId: 1,
  },
  {
    month: 'August',
    monthId: 1,
  },
  {
    month: 'September',
    monthId: 1,
  },
  {
    month: 'October',
    monthId: 1,
  },
  {
    month: 'November',
    monthId: 11,
  },
  {
    month: 'December',
    monthId: 12,
  },
]

const IndicatorMonthlyInput = (props: {years: number[]}) => {
  return (
    <div className='row'>
      {props.years.reverse().map((d) => {
        return (
          <Fragment>
            <h5 className='mt-2'>{d}</h5>
            {months.map((month) => {
              return (
                <div className='col-md-3'>
                  <p className='text-gray-600 fw-bold'>{month.month}</p>
                  <input type='number' className='form-control'></input>
                </div>
              )
            })}
          </Fragment>
        )
      })}
    </div>
  )
}

export {IndicatorMonthlyInput}
