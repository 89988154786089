/* eslint-disable jsx-a11y/anchor-is-valid */
import Select from 'react-select'

const options = [
  {value: 'smooth', label: 'Smooth'},
  {value: 'stepline', label: 'Stepline'},
  {value: 'straight', label: 'Straight'},
]

const StrokeSelect = ({onChange}: any) => (
  <Select
    options={options}
    onChange={(e) => {
      onChange(e?.value)
    }}
  />
)

export {StrokeSelect}
