import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../layout/core'
import {CompiledListWrapper} from './components/TemplateList'
import {CompanySelect} from '../../../components/dropdown/CompanySelect'
import {KTCard} from '../../../../_metronic/helpers/components/KTCard'
import {KTCardBody} from '../../../../_metronic/helpers'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Question',
    path: '/apps/viewquestion',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ViewQuestionPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}> Question Progress </PageTitle>
      <KTCard>
        <KTCardBody>
          <div className='row mt-1 mb-1'>
            <div className='col-md-2'>
              <label className='required fw-bold fs-6'>Company</label>
            </div>
            <div className='col-md-8' style={{width: 600}}>
              <CompanySelect></CompanySelect>
            </div>
          </div>
        </KTCardBody>
      </KTCard>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10 mt-1'>
        {/* begin::Col */}
        <div className='col-md-12 p-0'>
          <KTCard>
            <div className='card'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Compiled Question</span>
                </h3>
                {/* begin::Card toolbar */}
                <div className='card-toolbar w-100'>
                  {/* begin::Group actions */}
                  <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                    <li className='nav-item'>
                      <a
                        className='nav-link active'
                        data-bs-toggle='tab'
                        href='#kt_tab_environment'
                      >
                        Environment
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_social'>
                        Social
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_governance'>
                        Governance
                      </a>
                    </li>
                  </ul>
                  {/* end::Group actions */}
                </div>
                {/* end::Card toolbar */}

                <div className='card-body card-scroll h-850px p-0'>
                  <div className='tab-content' id='myTabContent'>
                    <div
                      className='tab-pane fade active show'
                      id='kt_tab_environment'
                      role='tabpanel'
                    >
                      <CompiledListWrapper />
                    </div>
                    <div className='tab-pane fade' id='kt_tab_social' role='tabpanel'>
                      <CompiledListWrapper />
                    </div>
                    <div className='tab-pane fade' id='kt_tab_governance' role='tabpanel'>
                      <CompiledListWrapper />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </KTCard>
        </div>
        {/* end::Col */}
      </div>
    </>
  )
}

export default ViewQuestionPage
