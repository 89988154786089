/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {IconUserModel} from '../../../app/modals/profile'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

type Props = {
  icon: string
  badgeColor: string
  status: string
  statusColor: string
  title: string
  description: string
  date: string
  budget: string
  progress: number
  users?: Array<IconUserModel>
}

const CompanyProfileCard: FC<Props> = ({
  icon,
  badgeColor,
  status,
  statusColor,
  title,
  description,
  progress,
  users = undefined,
}) => {
  return (
    <Link to='' className='card border border-2 border-gray-300 border-hover'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <div className='symbol symbol-50px w-50px bg-light'>
            <img src={toAbsoluteUrl(icon)} alt='CompanyProfileCard' className='p-3' />
          </div>
        </div>

        <div className='card-toolbar'>
          <span className={`badge badge-light-${badgeColor} fw-bolder me-auto px-4 py-3`}>
            {status}
          </span>
        </div>
      </div>

      <div className='card-body'>
        <div className='fs-3 fw-bolder text-dark'>{title}</div>

        <p className='text-gray-400 fw-bold fs-6'>{description}</p>
        <p className='text-gray-600 fs-6'>
          0123456779<br></br>bursa@email.com
        </p>

        {/* <UsersList users={users} /> */}
      </div>
    </Link>
  )
}

export {CompanyProfileCard}
