/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'
import {number} from 'yup'
import {IndicatorTemplateSelect} from '../../../../components/dropdown/IndicatorTemplateSelect'
import {StepProps, IndicatorCheck} from '../../../../modals/indicator'

const Step1 = ({data, updateData, hasError}: StepProps) => {}

const AddMainIndicatorButton = ({lastIndex, index, functionCall}: IndicatorCheck) => {
  console.log(lastIndex)
  console.log(index)
  if (index === lastIndex) {
    return (
      <Fragment>
        <button
          className='btn btn-sm btn-icon btn-light-success me-1'
          type='button'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Add new main indicator'
          onClick={() => functionCall()}
        >
          <i className='bi bi-plus-circle fs-3'></i>
        </button>
      </Fragment>
    )
  }
  return <></>
}

const AddSubIndicatorButton = ({lastIndex, index, functionCall}: IndicatorCheck) => {
  console.log(lastIndex)
  console.log(index)
  if (index === lastIndex) {
    return (
      <button
        className='btn btn-sm btn-icon btn-light-primary me-1'
        type='button'
        data-bs-toggle='tooltip'
        data-bs-placement='top'
        title='Add new sub indicator'
        onClick={() => functionCall()}
      >
        <i className='bi bi-plus-circle fs-3'></i>
      </button>
    )
  }
  return <></>
}

// const AddMainIndicatorButton: any({function :any, index :number})=(props)=>
// {
//   return (
//     <button
//     className='btn btn-sm btn-icon btn-light-success me-1'
//     type='button'
//     data-bs-toggle='tooltip'
//     onClick={() => updateMainIndicator!(true, categoryIndex)}
//   >
//     <i className='bi bi-plus-circle fs-3'></i>
//   </button>
//   )
// }

const Step2 = ({
  data,
  updateCategory,
  removeCategory,
  updateMainIndicator,
  removeMainIndicator,
  updateSubIndicator,
  removeSubIndicator,
  hasError,
}: StepProps) => {
  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Category */}
        <div className='card-body py-3'>
          <div className='row mb-8'>
            <div className='col-md-3 mt-2'>Copy From Template :</div>
            <div className='col-md-6'>
              <IndicatorTemplateSelect></IndicatorTemplateSelect>
            </div>
          </div>
          <div className='table-responsive'>
            <table className='w-100'>
              <thead className='bg-active-primary text-white active'>
                <tr style={{textAlign: 'left'}}>
                  <th className='w-450px'>PERFORMANCE INDICATOR</th>
                  <th className='w-150px'>UNITS</th>
                  <th className='w-20px'></th>
                  <th className='w-20px'></th>
                  <th className='w-20px'></th>
                </tr>
              </thead>

              {data.categories.map((val: any, categoryIndex) => {
                return (
                  <tbody>
                    <tr className='bg-success text-white fw-bold' style={{textAlign: 'left'}}>
                      <td colSpan={4}>
                        <input
                          className='w-100 bg-success border-0'
                          placeholder='title'
                          value={val.title}
                          onChange={(e) => {
                            const category = val
                            category.title = e.target.value
                            updateCategory!(false, category, categoryIndex)
                          }}
                        />
                      </td>
                      <td>
                        <button
                          className='btn btn-sm btn-icon btn-active-light-danger me-1'
                          type='button'
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          title='delete title'
                          onClick={() => {
                            removeCategory!(categoryIndex)
                          }}
                        >
                          <i className='bi bi-trash fs-3 text-danger'></i>
                        </button>
                      </td>
                    </tr>
                    {/* MainIndicator */}
                    {val.mainIndicators.map((mainIndicatorValue: any, mainIndicatorIndex: any) => {
                      return (
                        <Fragment>
                          <tr className='bg-light-primary hoverable' style={{textAlign: 'left'}}>
                            <td>
                              <input
                                className='w-100 bg-light-primary hoverable border-0'
                                placeholder='main indicator'
                                value={mainIndicatorValue.title}
                                onChange={(e) => {
                                  const mainIndicator = mainIndicatorValue
                                  mainIndicator.title = e.target.value
                                  updateMainIndicator!(
                                    false,
                                    categoryIndex,
                                    mainIndicator,
                                    mainIndicatorIndex
                                  )
                                }}
                              />
                            </td>
                            <td>
                              <input
                                className='w-100 bg-light-primary hoverable border-0'
                                placeholder='units'
                                value={mainIndicatorValue.unit}
                                onChange={(e) => {
                                  const mainIndicator = mainIndicatorValue
                                  mainIndicator.unit = e.target.value
                                  updateMainIndicator!(
                                    false,
                                    categoryIndex,
                                    mainIndicator,
                                    mainIndicatorIndex
                                  )
                                }}
                              />
                            </td>
                            <td>
                              <button
                                className='btn btn-sm btn-icon btn-light-danger me-1'
                                type='button'
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                title='delete indicator'
                                onClick={() => {
                                  removeMainIndicator!(categoryIndex, mainIndicatorIndex)
                                }}
                              >
                                <i className='bi bi-trash fs-3'></i>
                              </button>
                            </td>
                            <td>
                              <button
                                className='btn btn-sm btn-icon btn-light-info me-1'
                                type='button'
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                title='Add new sub indicator'
                                onClick={() =>
                                  updateSubIndicator!(true, categoryIndex, mainIndicatorIndex)
                                }
                              >
                                <i className='bi bi-arrow-return-left fs-3'></i>
                              </button>
                            </td>
                            <td>
                              <AddMainIndicatorButton
                                index={mainIndicatorIndex}
                                lastIndex={val.mainIndicators.length - 1}
                                functionCall={
                                  function () {
                                    updateMainIndicator!(true, categoryIndex)
                                  }!
                                }
                              ></AddMainIndicatorButton>
                            </td>
                          </tr>

                          {/* Sub Indicator */}
                          {mainIndicatorValue.subIndicators.map(
                            (subIndicatorValue: any, subIndicatorIndex: any) => {
                              return (
                                <tr
                                  className='bg-light-primary hoverable'
                                  style={{textAlign: 'left'}}
                                >
                                  <td>
                                    <input
                                      type='text'
                                      className='w-100 bg-light-primary hoverable border-0 mx-5'
                                      name='subIndicator'
                                      placeholder='sub indicator'
                                      value={subIndicatorValue.title}
                                      onChange={(e) => {
                                        const subIndicator = subIndicatorValue
                                        subIndicator.title = e.target.value
                                        updateSubIndicator!(
                                          false,
                                          categoryIndex,
                                          mainIndicatorIndex,
                                          subIndicator,
                                          subIndicatorIndex
                                        )
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type='text'
                                      className='w-100 bg-light-primary hoverable border-0'
                                      name='unit'
                                      placeholder='units'
                                      value={subIndicatorValue.unit}
                                      onChange={(e) => {
                                        const subIndicator = subIndicatorValue
                                        subIndicator.unit = e.target.value
                                        updateSubIndicator!(
                                          false,
                                          categoryIndex,
                                          mainIndicatorIndex,
                                          subIndicator,
                                          subIndicatorIndex
                                        )
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <button
                                      className='btn btn-sm btn-icon btn-light-danger me-1'
                                      type='button'
                                      data-bs-toggle='tooltip'
                                      data-bs-placement='top'
                                      title='delete indicator'
                                      onClick={() => {
                                        removeSubIndicator!(
                                          categoryIndex,
                                          mainIndicatorIndex,
                                          subIndicatorIndex
                                        )
                                      }}
                                    >
                                      <i className='bi bi-trash fs-3'></i>
                                    </button>
                                  </td>
                                  <td>
                                    <AddSubIndicatorButton
                                      index={subIndicatorIndex}
                                      lastIndex={mainIndicatorValue.subIndicators.length - 1}
                                      functionCall={
                                        function () {
                                          updateSubIndicator!(
                                            true,
                                            categoryIndex,
                                            mainIndicatorIndex
                                          )
                                        }!
                                      }
                                    ></AddSubIndicatorButton>
                                  </td>
                                  <td></td>
                                </tr>
                              )
                            }
                          )}
                        </Fragment>
                      )
                    })}
                  </tbody>
                )
              })}
            </table>
          </div>
        </div>
        <div>
          <button
            className='btn btn-md btn-light-primary me-1'
            type='button'
            onClick={() => updateCategory!(true)}
          >
            Add Title
          </button>
        </div>
        {/* <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Category</span>
          </label>
          <select
            className='form-control '
            id='categorySelect'
            // onChange={(e) =>
            //   // updateData({
            //   //   category: e.target.value,
            //   // })
            // }
            // value={data.category}
          >
            <option value='test'>GHG emissions</option>
            <option value='scope 1'>Scope 1</option>
          </select>
        </div>
        <div className='fv-row'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>Tags</span>

            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Period to input indicator value'
            ></i>
          </label>

          <div className='mb-10'>
            <select className='form-control' id='categorySelect'>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export {Step2}
