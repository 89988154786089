import {CompanyTable} from '../components/table/CompanyTable'
import {UserEditModal} from '../components/modal/UserEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import {TableHeader} from '../../../components/table-list/TableHeader'
import {CompanyTableHeader} from '../components/table/CompanyTableHeader'

const CompanyList = () => {
  return (
    <>
      <KTCard>
        <CompanyTableHeader></CompanyTableHeader>
        <CompanyTable />
      </KTCard>
      <UserEditModal />
    </>
  )
}

const CompanyListWrapper = () => <CompanyList />

export {CompanyListWrapper}
