/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/services/auth'
import {toAbsoluteUrl} from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label fw-bold bg-primary text-inverse-primary'>S</span>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.username} {currentUser?.email}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                {currentUser?.role}
              </span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      {currentUser?.role === 'company' && (
        <div>
          <div className='separator my-2'></div>

          <div className='menu-item px-5'>
            <a href='/apps/viewquestion/details' className='menu-link px-5'>
              <span className='menu-text'>My Progress</span>
              <span className='menu-badge'>
                <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>65%</span>
              </span>
            </a>
          </div>
        </div>
      )}
      {(currentUser?.role === 'pic' || currentUser?.role === 'reviewer') && (
        <div>
          <div className='separator my-2'></div>

          <div className='menu-item px-5'>
            <a href='/apps/question/answer' className='menu-link px-5'>
              <span className='menu-text'>My Question</span>
              <span className='menu-badge'>
                <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>45</span>
              </span>
            </a>
          </div>
        </div>
      )}

      {(currentUser?.role === 'company' ||
        currentUser?.role === 'pic' ||
        currentUser?.role === 'reviewer') && (
        <div>
          <div className='separator my-2'></div>

          <div className='menu-item px-5'>
            <a href='/apps/profile/overview' className='menu-link px-5'>
              <span className='menu-text'>My Profile</span>
            </a>
          </div>
        </div>
      )}

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
