/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();
const options = [
  { value: '1', label: 'Xiadani Dave' },
  { value: '2', label: 'Koay Zet Thum' },
  { value: '3', label: 'Soo Pik Koong' },
  { value: '4', label: 'Thoo Thee Vin' },
  { value: '5', label: 'Woo Choi Khi' },
  { value: '6', label: 'Adil Dahari bin Shaheila Musyrif' },
  { value: '7', label: 'V. T. Selvaraju' },
]

const UserSelect = () => (
  <Select
      closeMenuOnSelect={false}
      isMulti
      options={options  }
    />
  // <Select options={options} />
)
 
export {UserSelect}
