export type CompanyIndicator = {
  companyName: string
  indicatorPeriod: string
  startOn?: Date
  categories: Category[]
}

export type Category = {
  title: string
  tags: string[]
  mainIndicators: MainIndicator[]
}

export type Indicator = {
  title: string
  unit: string
  value?: string | number
}

export type MainIndicator = Indicator & {
  subIndicators: Indicator[]
}

export type StepProps = {
  data: CompanyIndicator
  updateData: (fieldsToUpdate: Partial<CompanyIndicator>) => void
  updateCategory?: (isNew: boolean, category?: Category, index?: any) => any
  removeCategory?: (categoryIndex: any) => any
  updateMainIndicator?: (
    isNew: boolean,
    categoryIndex: any,
    mainIndicator?: MainIndicator,
    index?: any
  ) => any
  removeMainIndicator?: (categoryIndex: any, mainIndicatorIndex: any) => any
  updateSubIndicator?: (
    isNew: boolean,
    categoryIndex: any,
    mainIndicatorIndex: any,
    subIndicator?: Indicator,
    subIndicatorIndex?: any
  ) => any
  removeSubIndicator?: (categoryIndex: any, mainIndicatorIndex: any, subIndicatorIndex: any) => any
  hasError: boolean
}

export const defaultIndicatorData: CompanyIndicator = {
  companyName: 'test',
  indicatorPeriod: '',
  startOn: new Date(),
  categories: [
    {
      title: '',
      tags: [],
      mainIndicators: [
        {
          title: '',
          unit: '',
          subIndicators: [],
        },
      ],
    },
  ],
}

export type IndicatorCheck = {
  lastIndex: number
  index: number
  functionCall: any
}
