import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import clsx from 'clsx'
import {ListLoading} from '../../../../../components/loading/ListLoading'
import {EsgTypeRadioButton} from '../../../../../components/radiobutton/EsgTypeRadio'

const editUserSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const UserEditModalForm = () => {
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

  return (
    <>
      <form id='kt_modal_add_company_form' className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <EsgTypeRadioButton></EsgTypeRadioButton>
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Question</label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
              placeholder='Question'
              rows={9}
              name='Question'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
            />

            {/* end::Input */}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            className='btn btn-light me-3'
            data-bs-dismiss='modal'
            data-kt-users-modal-action='cancel'
          >
            Discard
          </button>

          <button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit'>
            <span className='indicator-label'>Submit</span>
          </button>
        </div>
        {/* end::Actions */}
      </form>
    </>
  )
}

export {UserEditModalForm}
