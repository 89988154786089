/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../assets/ts/_utils'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  chartHeight: string
  options: ApexOptions
}

const DonutChart: React.FC<Props> = ({className, chartHeight, options}: Props) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, options!))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
      <div className='card-body'>
        <div
          ref={chartRef}
          id='kt_charts_widget_8_chart'
          style={{height: chartHeight}}
          className='card-rounded-bottom'
        ></div>
      </div>
    </div>
  )
}

export {DonutChart}

function getChartOptions(height: number, options: ApexOptions): ApexOptions {
  const {series, colors, title, chart, stroke, markers, labels, legend, dataLabels} = options

  const borderColor = getCSSVariableValue('--kt-gray-200')

  return {
    series: series ?? [],
    labels,
    chart: {
      fontFamily: 'inherit',
      type: 'donut',
      height: height,
      toolbar: chart?.toolbar ?? {show: false},
      sparkline: chart?.sparkline ?? {enabled: false},
    },
    title: title ?? {text: ''},
    colors,
    legend: {
      show: legend && legend.show,
      position: (legend && legend.position) || 'left',
      horizontalAlign: (legend && legend.horizontalAlign) || 'left',
      floating: (legend && legend.floating) || true,
      offsetX: (legend && legend.offsetX) || -5,
    },
    dataLabels: dataLabels ?? {
      enabled: true,
      formatter: function (val) {
        return Number(val).toFixed(2) + '%'
      },
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: stroke?.curve || 'smooth',
      show: true,
      width: 2,
      colors: stroke?.colors,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    markers: {
      colors: markers?.colors,
      strokeColors: markers?.strokeColors,
      strokeWidth: markers?.strokeWidth,
    },
  }
}
