/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../assets/ts/_utils'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  chartHeight: string
  options: ApexOptions
}

const LineChart: React.FC<Props> = ({className, chartHeight, options}: Props) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, options!))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
      <div className='card-body'>
        <div
          ref={chartRef}
          id='kt_charts_widget_8_chart'
          style={{height: chartHeight}}
          className='card-rounded-bottom'
        ></div>
      </div>
    </div>
  )
}

export {LineChart}

function getChartOptions(height: number, options: ApexOptions): ApexOptions {
  const {series, colors, title, chart, stroke, markers, yaxis, xaxis, legend, dataLabels} = options

  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const strokeColor = getCSSVariableValue('--kt-gray-300')

  //   const color1 = getCSSVariableValue('--kt-warning')
  //   const color1Light = getCSSVariableValue('--kt-warning-light')

  //   const color2 = getCSSVariableValue('--kt-success')
  //   const color2Light = getCSSVariableValue('--kt-success-light')

  //   const color3 = getCSSVariableValue('--kt-primary')
  //   const color3Light = getCSSVariableValue('--kt-primary-light')

  return {
    series: series ?? [],
    chart: {
      fontFamily: 'inherit',
      type: 'line',
      height: height,
      toolbar: chart?.toolbar ?? {show: false},
      sparkline: chart?.sparkline ?? {enabled: false},
    },
    title: {text: title?.text || '123', align: title?.align || 'center'},
    colors,
    legend: {
      show: legend && legend.show,
    },
    dataLabels: dataLabels ?? {enabled: false},
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: stroke?.curve || 'smooth',
      show: true,
      width: 2,
      colors: stroke?.colors,
    },
    xaxis: {
      offsetX: 0,
      offsetY: 0,
      categories: xaxis?.categories || [],
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: xaxis?.labels?.show || false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        show: Array.isArray(yaxis) ? yaxis[0].labels?.show : yaxis?.labels?.show,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '' + val + ''
        },
      },
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    markers: {
      colors: markers?.colors,
      strokeColors: markers?.strokeColors,
      strokeWidth: markers?.strokeWidth,
    },
  }
}
