import {TableSearchInput} from '../../../../../components/table-list/TableSearchInput'

const TemplateListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <TableSearchInput placeHolder='Search Question' />
    </div>
  )
}

const CompiledListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <TableSearchInput placeHolder='Search Question' />
    </div>
  )
}

export {TemplateListHeader, CompiledListHeader}
