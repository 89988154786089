/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import {RandomColor} from '../../../_metronic/helpers/UIHelpers'

type Props = {
  indicators: {
    title: string
    description: string
    category: string
    unit: string
    value?: string | number
    submit: any
  }[]
}

const IndicatorForm: React.FC<Props> = ({indicators}) => {
  return (
    <div className='table-responsive'>
      {/* begin::Table */}
      <table className='table align-middle gs-0 gy-3'>
        {/* begin::Table head */}

        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {indicators.map((indicator, index) => {
            return (
              <>
                <tr key={`${indicator.title}-${index}`}>
                  <td colSpan={5}>
                    <div className='d-flex align-items-center mb-6'>
                      {/*begin::Bullet*/}
                      <span
                        data-kt-element='bullet'
                        className={`bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-${RandomColor()}`}
                      />
                      {/*end::Bullet*/}
                      {/*begin::Info*/}
                      <div className='flex-grow-1 me-5'>
                        {/*begin::Description*/}
                        <div className='text-gray-700 fw-semibold fs-6'>
                          {indicator.description}
                        </div>
                        {/*end::Description*/}
                        {/*begin::Link*/}
                        <div className='text-gray-400 fw-semibold fs-7'>
                          {/*begin::Name*/}
                          <a href='#' className='text-primary opacity-75-hover fw-semibold'>
                            {indicator.category}
                          </a>
                          {/*end::Name*/}
                        </div>
                        {/*end::Link*/}
                        {/*begin::Time*/}
                        <span className='text-muted mt-1 fw-semibold fs-7'>{indicator.title}</span>
                        {/*end::Time*/}
                      </div>
                      {/*end::Info*/}
                    </div>
                  </td>
                  <td>
                    {/*begin::Action*/}
                    <input
                      type='number'
                      className='form-control border-1 text-center fw-normal'
                      name='unit'
                      width={'50px'}
                      onChange={(e) => {
                        indicator.value = e.target.value
                      }}
                      placeholder={indicator.unit}
                    />
                    {/*end::Action*/}
                  </td>
                </tr>
              </>
            )
          })}
        </tbody>
        {/* end::Table body */}
      </table>
    </div>
  )
}

export {IndicatorForm}
