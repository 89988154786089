import {Fragment} from 'react'
import date from 'date-and-time'

const quarter = [
  {
    name: 'Q1',
    id: 1,
  },
  {
    name: 'Q2',
    id: 1,
  },
  {
    name: 'Q3',
    id: 1,
  },
  {
    name: 'Q4',
    id: 1,
  },
]

const IndicatorQuarterlyInputModal = (props: {years: number[]}) => {
  return (
    <div className='row'>
      {props.years.reverse().map((d) => {
        return (
          <Fragment>
            <h5 className='mt-2'>{d}</h5>
            {quarter.map((quarter) => {
              return (
                <div className='col-md-3'>
                  <p className='text-gray-600 fw-bold'>{quarter.name}</p>
                  <input type='number' className='form-control'></input>
                </div>
              )
            })}
          </Fragment>
        )
      })}
    </div>
  )
}

export {IndicatorQuarterlyInputModal}
