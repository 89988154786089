import DateRangePicker from 'react-bootstrap-daterangepicker'
import {KTCard, KTCardBody} from '../../../../_metronic/helpers'
import {DateRangePickerBootstrap} from '../../../components/date/DateRangePicker'
import {TableExportButton} from '../../../components/table-list/TableExportButton'
import {TablePagination} from '../../../components/table-list/TablePagination'
import {TableSearchInput} from '../../../components/table-list/TableSearchInput'
import {PageLink, PageTitle} from '../../../layout/core'
import {UsersListHeader} from '../../user/components/header/UsersListHeader'
import {IndicatorTableHeaderFilter} from './component/IndicatorTableFilterButton'
import {IndicatorTableHeader} from './component/IndicatorTableHeader'
const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Indicator',
    path: '/apps/indicator/progress',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Indicator = () => {
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}> Indicator Progress </PageTitle>

      {/* begin::Col */}
      <div className='col-md-12 p-0'>
        <KTCard>
          <div className='card'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Indicator Progress</span>
              </h3>
              {/* begin::Card toolbar */}
              <div className='card-toolbar w-100'>
                {/* begin::Group actions */}
                <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                  <li className='nav-item'>
                    <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_daily'>
                      Daily
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_weekly'>
                      Weekly
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_monthly'>
                      Monthly
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_quarterly'>
                      Quarterly
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_yearly'>
                      Yearly
                    </a>
                  </li>
                </ul>
                {/* end::Group actions */}
              </div>
              {/* end::Card toolbar */}

              <div className='card-body card-scroll h-850px p-0'>
                <div className='tab-content' id='myTabContent'>
                  <div className='tab-pane fade active show' id='kt_tab_daily' role='tabpanel'>
                    <KTCard>
                      <IndicatorTableHeader></IndicatorTableHeader>
                      <KTCardBody className='py-4'>
                        <div className='table-responsive'>
                          <table
                            id='kt_table_users'
                            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                          >
                            <thead>
                              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                <th className='min-w-125px'>Indicator</th>
                                <th className='min-w-125px'>Date</th>
                                <th className='min-w-125px'></th>
                              </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-bold'>
                              <tr>
                                <td>GHG emissions intensity by Revenue</td>
                                <td>19 Nov 2022</td>
                                <td>
                                  <input type='text' className='form-control'></input>
                                </td>
                              </tr>
                              <tr>
                                <td>GHG emissions intensity by Revenue</td>
                                <td>20 Nov 2022</td>
                                <td>
                                  <input type='text' className='form-control'></input>
                                </td>
                              </tr>
                              <tr>
                                <td>GHG emissions intensity by Revenue</td>
                                <td>21 Nov 2022</td>
                                <td>
                                  <input type='text' className='form-control'></input>
                                </td>
                              </tr>
                              <tr>
                                <td>GHG emissions intensity by Revenue</td>
                                <td>22 Nov 2022</td>
                                <td>
                                  <input type='text' className='form-control'></input>
                                </td>
                              </tr>
                              <tr>
                                <td>GHG emissions intensity by Revenue</td>
                                <td>23 Nov 2022</td>
                                <td>
                                  <input type='text' className='form-control'></input>
                                </td>
                              </tr>
                              <tr>
                                <td>GHG emissions intensity by Revenue</td>
                                <td>24 Nov 2022</td>
                                <td>
                                  <input type='text' className='form-control'></input>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <TablePagination></TablePagination>
                      </KTCardBody>
                    </KTCard>
                  </div>
                  <div className='tab-pane fade' id='kt_tab_weekly' role='tabpanel'>
                    <IndicatorTableHeader></IndicatorTableHeader>
                    <table
                      id='kt_table_users'
                      className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    >
                      <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px'>Indicator</th>
                          <th className='min-w-125px'>Date</th>
                          <th className='min-w-125px'></th>
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-bold'>
                        <tr>
                          {/* <div className='row'>
                            <div className='col-md-2'> 21 Nov 2022</div>
                            <div className='col-md-2'> 21 Nov 2022</div>
                            <div className='col-md-2'> 21 Nov 2022</div>
                            <div className='col-md-2'> 21 Nov 2022</div>
                            <div className='col-md-2'> 21 Nov 2022</div>
                            <div className='col-md-2'> 21 Nov 2022</div>{' '}
                          </div> */}
                          {/* <div className='row'>
                            <div className='col-md-2'>
                              <input type='text' className='form-control'></input>
                            </div>
                            <div className='col-md-2'>
                              <input type='text' className='form-control'></input>
                            </div>
                            <div className='col-md-2'>
                              <input type='text' className='form-control'></input>
                            </div>
                            <div className='col-md-2'>
                              <input type='text' className='form-control'></input>
                            </div>
                            <div className='col-md-2'>
                              <input type='text' className='form-control'></input>
                            </div>
                            <div className='col-md-2'>
                              <input type='text' className='form-control'></input>
                            </div>
                           
                          </div> */}
                        </tr>

                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>Nov 1st - 7th (Nov - Week 1)</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>Nov 8th - 14th (Nov - Week 2)</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>Nov 15th - 21st (Nov - Week 3)</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>Nov 28th - Dec 4th (Nov - Week 4)</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>Dec 5th - 11th (Dec - Week 1)</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='tab-pane fade' id='kt_tab_monthly' role='tabpanel'>
                    <IndicatorTableHeader></IndicatorTableHeader>
                    <table
                      id='kt_table_users'
                      className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    >
                      <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px'>Indicator</th>
                          <th className='min-w-125px'>Month</th>
                          <th className='min-w-125px'></th>
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-bold'>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>January 2022</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>February 2022</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>March 2022</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>April 2022</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>June 2022</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>July 2022</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>August 2022</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>September 2022</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='tab-pane fade' id='kt_tab_quarterly' role='tabpanel'>
                    <IndicatorTableHeader></IndicatorTableHeader>
                    <table
                      id='kt_table_users'
                      className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    >
                      <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px'>Indicator</th>
                          <th className='min-w-125px'>Quarter</th>
                          <th className='min-w-125px'></th>
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-bold'>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>Q1 2022</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>Q2 2022</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>Q3 2022</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>Q4 2022</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>Q1 2021</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>Q2 2021</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>Q3 2021</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td>Q4 2021</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='tab-pane fade' id='kt_tab_yearly' role='tabpanel'>
                    <IndicatorTableHeader></IndicatorTableHeader>
                    <table
                      id='kt_table_users'
                      className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    >
                      <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px'>Indicator</th>
                          <th className='min-w-125px'>Quarter</th>
                          <th className='min-w-125px'></th>
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-bold'>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td> 2022</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                        <tr>
                          <td>GHG emissions intensity by Revenue</td>
                          <td> 2021</td>
                          <td>
                            <input type='text' className='form-control'></input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </KTCard>
      </div>
      {/* end::Col */}
    </>
  )
}

const IndicatorDetailsPage = () => <Indicator />

export {IndicatorDetailsPage}
