import {KTSVG} from '../../../_metronic/helpers'
import {CompanySelect} from '../dropdown/CompanySelect'
import {RoleSelect} from '../dropdown/RoleSelect'
import {UserSelect} from '../dropdown/UserSelectMultiple'

const AssignUserModal = () => {
  return (
    <>
      <div
        className='modal fade'
        id='kt_modal_assign_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
          {/* begin::Modal content */}
          <div className='modal-content' style={{height: 600}}>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Role</label>
                {/* end::Label */}

                {/* begin::Input */}
                <RoleSelect></RoleSelect>
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>User</label>
                {/* end::Label */}

                {/* begin::Input */}
                <UserSelect></UserSelect>
                {/* end::Input */}
              </div>
              {/* end::Input group */}
              <div className='text-center pt-15'>
                <button
                  type='reset'
                  className='btn btn-light me-3'
                  data-bs-dismiss='modal'
                  data-kt-users-modal-action='cancel'
                >
                  Discard
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                >
                  <span className='indicator-label'>Submit</span>
                </button>
              </div>
            </div>

            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}

      {/* end::Modal Backdrop */}
    </>
  )
}

export {AssignUserModal}
