import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {CompiledListWrapper, TemplateListrapper} from './components/table/TableWrapper'
import clsx from 'clsx'
import {CompanySelect} from '../../../components/dropdown/CompanySelect'
import {KTCard} from '../../../../_metronic/helpers/components/KTCard'
import {PageLink, PageTitle} from '../../../layout/core'
import {KTCardBody} from '../../../../_metronic/helpers'
import {EsgTypeRadioButton} from '../../../components/radiobutton/EsgTypeRadio'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Question',
    path: '/apps/compile/question',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CompileQuestionPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}> Compile Question </PageTitle>
      <KTCard>
        <KTCardBody>
          <div className='row mt-1 mb-1'>
            <div className='col-md-2'>
              <label className='required fw-bold fs-6'>Company</label>
            </div>
            <div className='col-md-8' style={{width: 600}}>
              <CompanySelect></CompanySelect>
            </div>
          </div>
        </KTCardBody>
      </KTCard>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10 mt-1'>
        {/* begin::Col */}
        <div className='col-md-6'>
          <KTCard>
            <div className='card'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Choose Question</span>
                </h3>

                {/* begin::Card toolbar */}
                <div className='card-toolbar w-100'>
                  {/* begin::Group actions */}
                  <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                    <li className='nav-item'>
                      <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                        Template
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                        Manual
                      </a>
                    </li>
                  </ul>
                  {/* end::Group actions */}
                </div>
                {/* end::Card toolbar */}

                <div className='card-body card-scroll h-850px p-0'>
                  <div className='tab-content' id='myTabContent'>
                    <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
                      <TemplateListrapper />
                    </div>
                    <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
                      <>
                        <KTCard>
                          <KTCardBody className='py-4'>
                            <div className='row'>
                              <div className='col-md-12'>
                                <form id='kt_modal_add_company_form' className='form' noValidate>
                                  {/* begin::Scroll */}
                                  <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                  >
                                    <EsgTypeRadioButton></EsgTypeRadioButton>
                                    {/* begin::Input group */}
                                    <div className='fv-row mb-7'>
                                      {/* begin::Label */}
                                      <label className='required fw-bold fs-6 mb-2'>Question</label>
                                      {/* end::Label */}

                                      {/* begin::Input */}
                                      <textarea
                                        placeholder='Question'
                                        rows={11}
                                        name='Question'
                                        className={clsx(
                                          'form-control form-control-solid mb-3 mb-lg-0'
                                        )}
                                        autoComplete='off'
                                      />

                                      {/* end::Input */}
                                    </div>
                                    {/* end::Input group */}
                                  </div>
                                  {/* end::Scroll */}

                                  {/* begin::Actions */}
                                  <div className='text-center pt-15'>
                                    <button
                                      type='submit'
                                      className='btn btn-primary'
                                      data-kt-users-modal-action='submit'
                                    >
                                      <span className='indicator-label'>
                                        <i className='bi bi-arrow-right-circle fs-3x'></i>
                                      </span>
                                    </button>
                                  </div>
                                  {/* end::Actions */}
                                </form>
                              </div>
                            </div>
                          </KTCardBody>
                        </KTCard>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </KTCard>
        </div>
        {/* end::Col */}
        <div className='col-md-1 p-0 text-center' style={{marginTop: '30%'}}>
          <button type='button' className='btn btn-primary me-3'>
            <i className='bi bi-arrow-right-circle fs-3x'></i>
          </button>
        </div>

        {/* begin::Col */}
        <div className='col-md-5 p-0'>
          <KTCard>
            <div className='card'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Compiled Question</span>
                </h3>
                {/* begin::Card toolbar */}
                <div className='card-toolbar w-100'>
                  {/* begin::Group actions */}
                  <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                    <li className='nav-item'>
                      <a
                        className='nav-link active'
                        data-bs-toggle='tab'
                        href='#kt_tab_environment'
                      >
                        Environment
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_social'>
                        Social
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_governance'>
                        Governance
                      </a>
                    </li>
                  </ul>
                  {/* end::Group actions */}
                </div>
                {/* end::Card toolbar */}

                <div className='card-body card-scroll h-850px p-0'>
                  <div className='tab-content' id='myTabContent'>
                    <div
                      className='tab-pane fade active show'
                      id='kt_tab_environment'
                      role='tabpanel'
                    >
                      <CompiledListWrapper />
                    </div>
                    <div className='tab-pane fade' id='kt_tab_social' role='tabpanel'>
                      <CompiledListWrapper />
                    </div>
                    <div className='tab-pane fade' id='kt_tab_governance' role='tabpanel'>
                      <CompiledListWrapper />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </KTCard>
        </div>
        {/* end::Col */}
      </div>
    </>
  )
}

export default CompileQuestionPage
