import {KTCard} from '../../../../../_metronic/helpers'
import {AnswerQuestionModal} from '../../../../components/modal/AnswerQuestionModal'
import {TableHeader} from '../../../../components/table-list/TableHeader'
import {AnswerQuestionTable} from '../components/AnswerQuestionTable'

const TemplateList = () => {
  return (
    <>
      <KTCard>
        <TableHeader placeHolder='Search Question' />
        <AnswerQuestionTable />
      </KTCard>
      <AnswerQuestionModal></AnswerQuestionModal>
    </>
  )
}

const TemplateListrapper = () => <TemplateList />

export {TemplateListrapper}
