/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import Select from 'react-select'

const options = [
  { value: '1', label: 'PIC' },
  { value: '2', label: 'Approver' },
  { value: '3', label: 'Reviewer' }, 
]

const RoleSelect = () => (
  <Select options={options} />
)
 
export {RoleSelect}
