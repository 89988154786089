type Props = {
  className: string
  description: string
  color: string
  title: string
}

const CardsWidget = ({className, description, color, title}: Props) => (
  <div
    className={`card card-flush ${className}`}
    style={{
      backgroundColor: color,
    }}
  >
    <div className='card-header'>
      <div className='card-title'>
        <span className='fs-15 opacity-75 fw-bold text-white me-2 lh-1 ls-n2'>{title}</span>
      </div>

      <div className='card-body d-flex align-items-end'>
        <div className='d-flex align-items-center flex-column mt-3 w-100'>
          <div className='d-flex justify-content-between text-white w-100 mt-auto'>
            <span className='text-white fs-2hx fw-bold'>{description}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
)
export {CardsWidget}
