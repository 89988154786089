/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {CompanyAdminStatistics} from './components/Statistic'

const CompanyAdminDashboard: FC = () => {
  return (
    <>
      <CompanyAdminStatistics></CompanyAdminStatistics>
    </>
  )
}

export {CompanyAdminDashboard}
