/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuCompany = () => {
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/communication/com006.svg'
        title='User'
        fontIcon='bi bi-circle-square fs-2x'
      />

      <SidebarMenuItem
        to='/apps/question/details'
        icon='/media/icons/duotune/general/gen005.svg'
        title='View Q&A '
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/apps/indicator/assign'
        icon='/media/icons/duotune/general/gen032.svg'
        title='View Indicator'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/apps/resources/files'
        icon='/media/icons/duotune/files/fil012.svg'
        title='Resources'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/logout'
        icon='/media/icons/duotune/arrows/arr002.svg'
        title='Sign Out'
        fontIcon='bi-app-indicator'
      />
    </>
  )
}

export {SidebarMenuCompany}
