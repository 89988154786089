import React from 'react'

type Props = {
  submit?: any
}

const FillIndicatorModal: React.FC<Props> = () => {
  return (
    <>
      <div
        className='modal fade'
        id='fill_indicator_modal'
        aria-labelledby='fill_indicator_modal_label'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='fill_indicator_modal_label'>
                Indicator submit confirmation
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>Are you sure want to submit the changes?</div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Close
              </button>
              <button type='button' onClick={() => {}} className='btn btn-primary'>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {FillIndicatorModal}
