import {ListLoading} from '../../../../components/loading/ListLoading'
import {TablePagination} from '../../../../components/table-list/TablePagination'
import {Tooltip} from 'react-bootstrap'
import {KTCardBody} from '../../../../../_metronic/helpers'

const AnswerQuestionTable = () => {
  const isLoading = false

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th></th>
                <th className='min-w-125px'>Question</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>
              <tr>
                <td>1.</td>
                <td>
                  <span className='badge badge-light-warning'>In Progress</span>
                  <br></br>
                  Please list the firm’s three primary risks related to climate change and if any,
                  the firm´s climate-related opportunities
                </td>

                <td>
                  <a
                    href='#'
                    className='btn btn-sm fw-bold btn-light-primary mb-2'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_answer_question'
                  >
                    <i className='bi bi-vector-pen text-info'></i>
                  </a>
                  <br></br>
                  <a
                    href='#'
                    className='btn btn-sm fw-bold btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_answer_question'
                  >
                    <i className='bi bi-check-circle text-success'></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>
                  <span className='badge badge-light-info'>Answered</span>
                  <br></br>How are purchases and waste managed? If the firm rely on any scarce
                  resources, please describe what efforts are made to mitigate the risk of those
                  resources becoming scarcer in the future, e.g. recycling, substitutes, or improved
                  resource efficiency?
                </td>
                <td></td>
              </tr>
              <tr>
                <td>3.</td>
                <td>
                  <span className='badge badge-light-primary'>Reviewed</span>
                  <br></br>
                  Please list the firm’s (1-2) primary means of making a positive environmental
                  impact or minimising negative environmental impact
                </td>
                <td></td>
              </tr>
              <tr>
                <td>4.</td>
                <td>
                  <span className='badge badge-light-success'>Approved</span>
                  <br></br>
                  Provide an overview of your business and the market environment in which you
                  operate to contextualise your reporting for the reader.
                </td>
                <td></td>
              </tr>
              <tr>
                <td>5.</td>
                <td>
                  <span className='badge badge-light-danger'>Adjust</span>
                  <br></br>
                  Does the firm have a history of accidents? If so, how have these been managed? Are
                  there any preventive measures, such as policies?
                  {/* begin::Separator */}
                  <div className='separator mb-4'></div>
                  {/* end::Separator */}
                  {/* begin::Info */}
                  <div className='d-flex flex-column flex-row-fluid'>
                    {/* begin::Info */}
                    <div className='d-flex align-items-center flex-wrap mb-1 mt-2'>
                      <a href='#' className='text-gray-800 text-hover-primary fw-bold me-2'>
                        Alice Danchik
                      </a>

                      <span className='text-gray-400 fw-semibold fs-7'>1 day</span>
                    </div>
                    {/* end::Info */}

                    {/* begin::Post */}
                    <span className='text-gray-800 fs-7 fw-normal pt-1'>
                      provide more attachment
                    </span>
                    {/* end::Post */}
                    <div className='separator mb-4'></div>
                    {/* begin::Info */}
                    <div className='d-flex align-items-center flex-wrap mb-1 mt-2'>
                      <a href='#' className='text-gray-800 text-hover-primary fw-bold me-2'>
                        Ben Lee
                      </a>

                      <span className='text-gray-400 fw-semibold fs-7'>3 day</span>
                    </div>
                    {/* end::Info */}

                    {/* begin::Post */}
                    <span className='text-gray-800 fs-7 fw-normal pt-1'>not enough info</span>
                    {/* end::Post */}
                  </div>
                  {/* end::Info */}
                </td>
                <td>
                  <Tooltip title='Delete'>
                    <a
                      href='#'
                      className='btn btn-sm fw-bold btn-light-primary mb-2'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_answer_question'
                    >
                      <i className='bi bi-vector-pen text-info'></i>
                    </a>
                  </Tooltip>
                  <br></br>
                  <a
                    href='#'
                    className='btn btn-sm fw-bold btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_answer_question'
                  >
                    <i className='bi bi-check-circle text-success'></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>6.</td>
                <td>
                  <span className='badge badge-light-warning'>In Progress</span>
                  <br></br>
                  Are your employees inspired by your efforts to become a more climate conscious,
                  socially responsible, ethically led organization?
                </td>
                <td>
                  <a
                    href='#'
                    className='btn btn-sm fw-bold btn-light-primary mb-2'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_answer_question'
                  >
                    <i className='bi bi-vector-pen text-info'></i>
                  </a>
                  <br></br>
                  <a
                    href='#'
                    className='btn btn-sm fw-bold btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_answer_question'
                  >
                    <i className='bi bi-check-circle text-success'></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>7.</td>
                <td>
                  <span className='badge badge-light-warning'>In Progress</span>
                  <br></br>
                  Please state your targets for gender and cultural equality and indicate the
                  relative split of men/women at every level of the firm, particularly the Board of
                  Directors and management team
                </td>
                <td>
                  <a
                    href='#'
                    className='btn btn-sm fw-bold btn-light-primary mb-2'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_answer_question'
                  >
                    <i className='bi bi-vector-pen text-info'></i>
                  </a>
                  <br></br>
                  <a
                    href='#'
                    className='btn btn-sm fw-bold btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_answer_question'
                  >
                    <i className='bi bi-check-circle text-success'></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>8.</td>
                <td>
                  <span className='badge badge-light-warning'>In Progress</span>
                  <br></br>
                  Please list the firm’s three primary risks related to climate change and if any,
                  the firm´s climate-related opportunities
                </td>
                <td>
                  <a
                    href='#'
                    className='btn btn-sm fw-bold btn-light-primary mb-2'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_answer_question'
                  >
                    <i className='bi bi-vector-pen text-info'></i>
                  </a>
                  <br></br>
                  <a
                    href='#'
                    className='btn btn-sm fw-bold btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_answer_question'
                  >
                    <i className='bi bi-check-circle text-success'></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>9.</td>
                <td>
                  <span className='badge badge-light-warning'>In Progress</span>
                  <br></br>
                  Does the firm have a history of accidents? If so, how have these been managed? Are
                  there any preventive measures, such as policies?
                </td>
                <td>
                  <a
                    href='#'
                    className='btn btn-sm fw-bold btn-light-primary mb-2'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_answer_question'
                  >
                    <i className='bi bi-vector-pen text-info'></i>
                  </a>
                  <br></br>
                  <a
                    href='#'
                    className='btn btn-sm fw-bold btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_answer_question'
                  >
                    <i className='bi bi-check-circle text-success'></i>
                  </a>
                </td>
              </tr>

              <tr>
                <td>10.</td>
                <td>
                  <span className='badge badge-light-success'>Approved</span>
                  <br></br>
                  How are purchases and waste managed? If the firm rely on any scarce resources,
                  please describe what efforts are made to mitigate the risk of those resources
                  becoming scarcer in the future, e.g. recycling, substitutes, or improved resource
                  efficiency?
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <TablePagination />
        <TablePagination />
        {isLoading && <ListLoading />}
      </KTCardBody>
    </>
  )
}

export {AnswerQuestionTable}
