import DateRangePicker from 'react-bootstrap-daterangepicker'
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css'
import React from 'react'



function handleCallback(start: any, end: any, label: any) {
  console.log(start, end, label)
}


const DateRangePickerBootstrap = () => (

 
  <DateRangePicker  >
    <input type='text' className='form-control form-control-solid w-250px ps-14'  />
  </DateRangePicker>
)

export {DateRangePickerBootstrap}
