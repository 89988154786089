import {ApexOptions} from 'apexcharts'
import {FC, ReactNode, useState} from 'react'
import {CompanyProfileCard} from '../../../../components/card/CompanyProfile'
import {ProgressCard} from '../../../../components/card/Progress'
import {CountCard} from '../../../../components/card/Count'
import {LogList} from '../../../../components/list/LogList'
import {LineChart} from '../../../../components/graph/Line'
import {PieChart} from '../../../../components/graph/Pie'
import {BarChart} from '../../../../components/graph/Bar'
import {AreaChart} from '../../../../components/graph/Area'
import {GraphGeneratorModal} from '../../../../components/modal/GraphGeneratorModal'
import {getCSSVariableValue} from '../../../../assets/ts/_utils'
import { AnswerQuestionModal } from '../../../../components/modal/AnswerQuestionModal'

const taskOverviewLineOptions: ApexOptions = {
  title: {
    text: 'Task Overview',
    align: 'center',
  },
  series: [
    {
      name: 'Answered',
      data: [30, 30, 50, 50, 35, 35],
    },
    {
      name: 'Reviewed',
      data: [55, 20, 20, 20, 70, 70],
    },
    {
      name: 'Approved',
      data: [60, 60, 40, 40, 30, 30],
    },
  ],
  xaxis: {
    categories: ['jan', 'feb', 'mar', 'apr', 'may', 'jun'],
  },
}

const SuperAdminStatistic: FC = () => {
  const [graph, setGraph] = useState()

  const color1 = getCSSVariableValue('--kt-warning')
  const color1Light = getCSSVariableValue('--kt-warning-light')

  const color2 = getCSSVariableValue('--kt-success')
  const color2Light = getCSSVariableValue('--kt-success-light')

  const color3 = getCSSVariableValue('--kt-primary')
  const color3Light = getCSSVariableValue('--kt-primary-light')

  const color4 = getCSSVariableValue('--kt-info')
  const color4Light = getCSSVariableValue('--kt-info-light')

  const taskOverviewBarOptions: ApexOptions = {
    title: {
      text: 'Task Bar Overview',
      align: 'center',
    },
    series: [
      {
        name: 'Answered',
        data: [30, 30, 50, 50, 35, 35],
      },
      {
        name: 'Reviewed',
        data: [55, 20, 20, 20, 70, 70],
      },
      {
        name: 'Approved',
        data: [60, 60, 40, 40, 30, 30],
      },
    ],
    xaxis: {
      categories: ['jan', 'feb', 'mar', 'apr', 'may', 'jun'],
    },
  }

  const taskOverviewPieOptions: ApexOptions = {
    title: {
      text: 'Overall Activity',
      align: 'left',
    },
    series: [44, 55, 13, 33],
    labels: ['Answered', 'In Progress', 'Reviewed', 'Approved'],
    legend: {
      show: true,
    },
    colors: [color1, color2, color3, color4],
    markers: {
      strokeWidth: 8,
    },
    stroke: {
      colors: [color1, color2, color3, color4],
    },
  }

  const taskOverviewAreaOptions: ApexOptions = {
    title: {
      text: 'Task Overview',
      align: 'center',
    },
    series: [
      {
        name: 'Answered',
        data: [30, 30, 50, 50, 35, 35],
      },
      {
        name: 'Reviewed',
        data: [55, 20, 20, 20, 70, 70],
      },
      {
        name: 'Approved',
        data: [60, 60, 40, 40, 30, 30],
      },
    ],
    xaxis: {
      categories: ['jan', 'feb', 'mar', 'apr', 'may', 'jun'],
    },
    colors: [color1Light, color2Light, color3Light],
    markers: {
      colors: [color1, color2, color3],
      strokeColors: [color1, color2, color3],
      strokeWidth: 3,
    },
    stroke: {
      colors: [color1, color2, color3],
    },
  }

  return (
    <>
      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-3'>
          {/* <MixedWidget16 className='card-xxl-stretch' chartColor='success' chartHeight='300' /> */}

          <CompanyProfileCard
            icon='https://www.bernama.com/storage/photos/906ee1715b9eca23c3179f92237d8a0c5c9510867dc19'
            badgeColor='warning'
            status='In Progress'
            statusColor='warning'
            title='Bursa Malaysia'
            description='Eric Finn (Director)'
            progress={60}
            date={''}
            budget={''}
          />
        </div>
        <div className='col-xxl-3'>
          <ProgressCard
            className='card-xxl-stretch'
            color='warning'
            title='(25/100)'
            description='Overall Progress'
            progress='25%'
            showUser={true}
            users={['Adrian', 'Sunny', 'Steven', 'Kelvin', 'Pauline', 'Kendrick', 'Lee Hi']}
          />
        </div>
        <div className='col-xxl-6'>
          {/* <MixedWidget7
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='200px'
          /> */}
          <PieChart
            options={taskOverviewPieOptions}
            chartHeight='200px'
            className='card-xxl-stretch'
          />
        </div>
      </div>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <CountCard
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen032.svg'
            color='white'
            iconColor='primary'
            title='64'
            description='Answered'
          />
        </div>

        <div className='col-xl-3'>
          <CountCard
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
            color='white'
            iconColor='success'
            title='38'
            description='Reviewed'
          />
        </div>

        <div className='col-xl-3'>
          <CountCard
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/finance/fin006.svg'
            color='white'
            iconColor='warning'
            title='21'
            description='Approved'
          />
        </div>

        <div className='col-xl-3'>
          <CountCard
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='white'
            iconColor='info'
            title='2'
            description='Pending'
          />
        </div>
      </div>
      {/* end::Row */}
      <div className='row gy-10 gx-xl-10'>
        <div className='col-xxl-6'>
          <AreaChart
            className='card-xxl-stretch mb-5 mb-xl-10'
            chartHeight='350px'
            options={taskOverviewAreaOptions}
          />
        </div>
        <div className='col-xl-6'>
          <LogList
            className='card-xxl-stretch mb-5 mb-xl-10'
            logOptions={{
              title: 'Activities',
              description: '78 Questions',
              data: [
                {
                  title: 'Answered Question',
                  options: {
                    bold: false,
                    color: 'warning',
                  },
                },
                {
                  title:
                    'Question XXXX <span class="badge badge-light-success">Approved</span> by<a href="/apps/profile/overview" class="text-primary">#Xandier9910</a>',
                  // { ReactHtmlParser('')},
                  // {parseHtml('<h1>Hello html-react-parser</h1>')},

                  options: {
                    bold: true,
                    color: 'success',
                  },
                },
                {
                  title:
                    'Reviewer Ben99910 <span class="badge badge-light-warning">commented</span>  on question 1',
                  options: {
                    bold: true,
                    color: 'error',
                  },
                },
                {
                  title:
                    'Reviewer Ben99910 <span class="badge badge-light-warning">commented</span>  on question 1',
                  options: {
                    bold: true,
                    color: 'error',
                  },
                },
                {
                  title:
                    'Question XXXX <span class="badge badge-light-success">Approved</span> by<a href="/apps/profile/overview" class="text-primary">#kin810</a>',
                  options: {
                    bold: true,
                    color: 'success',
                  },
                },
                {
                  title:
                    'Question XXXX <span class="badge badge-light-success">Approved</span> by<a href="/apps/profile/overview" class="text-primary">#Xandier9910</a>',
                  // { ReactHtmlParser('')},
                  // {parseHtml('<h1>Hello html-react-parser</h1>')},
                  options: {
                    bold: true,
                    color: 'success',
                  },
                },
                {
                  title:
                    'Reviewer Ben99910 <span class="badge badge-light-warning">commented</span>  on question 1',
                  options: {
                    bold: true,
                    color: 'error',
                  },
                },
                {
                  title: 'Answered Question',
                  options: {
                    bold: false,
                    color: 'warning',
                  },
                },
                {
                  title:
                    'Reviewer Ben99910 <span class="badge badge-light-warning">commented</span>  on question 1',
                  options: {
                    bold: true,
                    color: 'error',
                  },
                },
              ],
            }}
          />
        </div>
      </div>

      <AnswerQuestionModal></AnswerQuestionModal>
    </>
  )
}

export {SuperAdminStatistic}
