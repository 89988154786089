import {KTSVG} from '../../../_metronic/helpers'

const TableExportButton = () => {
  const openAddUserModal = () => {}

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button>
      {/* end::Export */}
    </div>
  )
}

export {TableExportButton }
