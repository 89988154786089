import React from 'react'

type Props = {
  label?: {
    text?: string
    tooltip?: {
      text?: string
      show?: boolean
    }
  }
  onChange: any
  input?: {
    isRequired?: boolean
  }
  options: {
    name: string
    id: string
    label?: string
  }[]
}

const SwitchInput: React.FC<Props> = ({label, input, options, onChange}: Props) => {
  return (
    <div>
      <label className='d-flex align-items-center fs-5 mb-2'>
        <span className={input?.isRequired ? 'required' : ''}>{label?.text}</span>
        {label?.tooltip?.show && (
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title={label?.tooltip?.text}
          ></i>
        )}
      </label>

      <div className='mb-10 d-flex'>
        {options.map((option, index) => {
          return (
            <div className='form-check form-switch me-10' key={option.id + index}>
              <input
                className='form-check-input '
                type='checkbox'
                // value={option.value}
                id={option.id}
                name={option.name}
                onChange={(e) => {
                  onChange(e.target.checked)
                }}
              />
              <label className='form-check-label' htmlFor={option.id}>
                {option.label}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export {SwitchInput}
