/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react'
import {initialQueryState, KTSVG, useDebounce} from '../../../_metronic/helpers'

const App = ({message}: {message: string}) => <div>{message}</div>

const TableSearchInput = (props: {placeHolder: string}) => {
  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder={props.placeHolder}
        />
      </div>
      {/* end::Search */}
    </div>
  )
}

export {TableSearchInput}
