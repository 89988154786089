/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import parse from 'html-react-parser';

type Log = {
  title: any
  options?: {
    bold?: boolean
    color?: 'warning' | 'success' | 'error'
  }
}
type Props = {
  className: string
  logOptions: {
    title: string
    description: string
    optionButtion?: {
      fn?: Function
    }
    data: Log[]
  }
}

const _getColor = (color: string) => {
  switch (color) {
    case 'warning':
      return 'text-warning'
    case 'success':
      return 'text-success'
    case 'error':
      return 'text-danger'
    default:
      return 'text-success'
  }
}

const LogList: React.FC<Props> = ({className, logOptions}) => {
  const {title, description, optionButtion = {}, data = []} = logOptions
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>{title}</span>
          <span className='text-muted fw-semibold fs-7'>{description}</span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            // onClick={() => {
            //   optionButtion.fn || function () {}
            // }}
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {/* begin::Item */}
          {data.map((log, index) => {
            return (
              <div className='timeline-item' key={index}>
                {/* begin::Label */}
                <div className='timeline-label fw-bold text-gray-800 fs-6'></div>
                {/* end::Label */}
                {/* begin::Badge */}
                <div className='timeline-badge'>
                  <i
                    className={`fa fa-genderless fs-1 ${
                      log.options?.color && _getColor(log.options.color)
                    }`}
                  ></i>
                </div>
                {/* end::Badge */}
                {/* begin::Content */}
                <div className='timeline-content d-flex'>
                  <span
                    className={
                      log.options?.bold ? 'fw-bold text-gray-800 ps-3' : 'text-gray-800 ps-3'
                    }
                  >
                    {parse( log.title)}
                  </span>
                </div>
                {/* end::Content */}
              </div>
            )
          })}
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export {LogList}
